import { substringMatcher } from './substringMatcher.js';
import { setup_one_platform_view } from '../views/one-platform/one-platform-view.js';
import {genesWorker, ncbiGeneWorker} from "../workers";
import {create_study_table} from "../views/one-platform/studies-table/studies-table";
import {gene_worker_listener} from "../views/one-platform/scatter-chart/coordinator";
import {gene_worker_listener_onestudy} from "../util/plot-components/plotly-chart";
import {save_analytics_event} from "../analytics/analytics";
import {get} from "../util/index_db";

var all_genes;
var sym2id;
var g1;
var g2;
var config;
var custom;
var config2;
var custom2;

export function typeahead_listener(msg){
    if(localStorage.view === 'one_platform'){
        if(msg.data.msg.method === 'GET_PLATFORM'){
            all_genes = msg.data.all_genes;
            sym2id = msg.data.sym2id;
            if(!localStorage.getItem('gene_1')){
                localStorage.setItem('gene_1', JSON.stringify(msg.data.g1));
            }
            if(!localStorage.getItem('gene_2')){
                localStorage.setItem('gene_2', JSON.stringify(msg.data.g2));
            }
            // set up the typeahead
            setup();
            // notify the one platforms view listener that the inputs are ready.
            setup_one_platform_view();
        }
    }

}

genesWorker.addEventListener("message", function handleMessageFromWorker(msg) {
    const view = localStorage.getItem('view');
    if(view === 'one_platform') {
        gene_worker_listener(msg);
        gene_worker_listener_onestudy(msg, false)
    }else{
        gene_worker_listener_onestudy(msg, true)
    }
});

var setup = function() {
    var platform = JSON.parse(localStorage.getItem('platform'));
    g1 = JSON.parse(localStorage.getItem('gene_1'));
    $('#gene_1').typeahead('destroy');
    $('#gene_1').val(g1.value);
    g2 = JSON.parse(localStorage.getItem('gene_2'));
    $('#gene_2').typeahead('destroy');
    $('#gene_2').val(g2.value);
    config = {
        highlight: true,
        minLength: 0,
        maxLength: 20,
        classNames: {
            input: 'form-control',
        }
    };

    custom = {
        name: 'all_genes',
        limit: 10,
        source: substringMatcher(all_genes),
        display: 'value',
        templates: {
            empty: [
                '<div class="empty-message">',
                'Unable to find any genes that match the current query.',
                '</div>'
            ].join('\n'),
            suggestion: Handlebars.compile('<div><strong>{{value}}</strong>:{{name}}</div>')
        }
    }

    $('#gene_1').typeahead(config,custom).off('typeahead:selected').on('typeahead:selected', function(evt, item) {
        localStorage.setItem('gene_1', JSON.stringify(item));
        save_analytics_event({name: 'gene_view', attributes: item});
        genesWorker.postMessage({"method": "GET", "Gene": item, "query": {GeneId: item.gene_id, platform: platform.Platform}, 'tag': 'x_axis'});
        $("#spinner_loading_input").show();
        const view = localStorage.getItem('view');
        if(view === 'one_platform'){
            create_study_table();
        }
    })
    genesWorker.postMessage({"method": "GET", "Gene": g1, "query": {GeneId: g1.gene_id, platform: platform.Platform}, 'tag': 'x_axis'});

    config2 = {
        highlight: true,
        minLength: 0,
        maxLength: 20,
        classNames: {
            input: 'form-control',
        }
    };

    custom2 = {
        name: 'all_genes',
        limit: 10,
        source: substringMatcher(all_genes),
        display: 'value',

        templates: {
            empty: [
                '<div class="empty-message">',
                'Unable to find any genes that match the current query.',
                '</div>'
            ].join('\n'),
            suggestion: Handlebars.compile('<div><strong>{{value}}</strong>:{{name}}</div>')
        }
    }
    $('#gene_2').typeahead(config2,custom2).off('typeahead:selected').on('typeahead:selected', function(evt, item) {
        localStorage.setItem('gene_2', JSON.stringify(item));
        save_analytics_event({name: 'gene_view', attributes: item});
        genesWorker.postMessage({method: "GET", Gene: item, query: {GeneId: item.gene_id, platform: platform.Platform}, tag: 'y_axis'});
        $("#spinner_loading_input").show();
        const view = localStorage.getItem('view');
        if(view === 'one_platform'){
            create_study_table();
        }
    })
    genesWorker.postMessage({method: "GET", Gene: g2, query: {GeneId: g2.gene_id, platform: platform.Platform}, tag: 'y_axis'});
}
