/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "95a664d5c12b4c0698e77989a7cb4ef6",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "95a664d5c12b4c0698e77989a7cb4ef6",
            "region": "us-east-1"
        }
    },
    "aws_cognito_identity_pool_id": "us-east-1:f896ebdc-cbeb-428b-938d-8e43e4e31af9",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_cK5Unumhe",
    "aws_user_pools_web_client_id": "7rs1ifqg9v94rottbil6dhtqp9",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "omics-storage-d6610f1723153-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
