// Controlled by users to change the chart plot type.
import {update_plot} from "./plotly-chart.js";
import {get, set} from '../index_db.js';
import {save_analytics_event} from "../../analytics/analytics";


var handleClick = function(radio){
    if(radio.target.value == 'Scatterplot'){
        localStorage.setItem('plotly_type', 'scattergl');
        update_plot('configure', 'scattergl');
        save_analytics_event({name: 'plot_type', attributes: {'type': 'scatterplot'}});
    }else if(radio.target.value == 'Bar Chart'){
        localStorage.setItem('plotly_type', 'bar');
        update_plot('configure', 'bar');
        save_analytics_event({name: 'plot_type', attributes: {'type': 'barchart'}});
    }else if(radio.target.value == 'Boxplot'){
        localStorage.setItem('plotly_type', 'box');
        update_plot('configure', 'box');
        save_analytics_event({name: 'plot_type', attributes: {'type': 'boxplot'}});
    }
    // rerun the plot function
}


export function setConfigureChartbyCheckboxes(){
    var selected = localStorage.getItem('plotly_type');
    switch(selected){
        case 'scattergl':
            selected = 'Scatterplot';
            break;
        case 'boxplot':
            selected = 'Bar Chart';
            break;
        case 'barchart':
            selected = 'Boxplot';
            break;
    }
    var container = document.getElementById('checkboxesConfigureChart');
    if(container){
        container.innerHTML='';
    }
    for (var value of ['Scatterplot', 'Bar Chart', 'Boxplot']) {
        var myEle = document.getElementById('configurechart-'+value);
        if(!myEle){
            if(value === selected){
                $('#checkboxesConfigureChart').append(`<div class="form-check" id="configurechart-${value}+1">`)
                .append(`<input checked class="form-check-input" type="radio" id="configurechart-${value}" name="configurechartRadios" value="${value}" checked>`)
                .append(`<label id="configurechart-${value}-label" class="form-check-label" for="${value}">${value}</label></div>`)
                .append(`</div>`)
            }else{
                $('#checkboxesConfigureChart').append(`<div class="form-check" id="configurechart-${value}+1">`)
            .append(`<input class="form-check-input" type="radio" id="configurechart-${value}" name="configurechartRadios" value="${value}">`)
            .append(`<label id="configurechart-${value}-label" class="form-check-label" for="${value}">${value}</label></div>`)
            .append(`</div>`)
            }
        }
    }
    document.querySelectorAll("input[name='configurechartRadios']").forEach((input) => {
        input.addEventListener('change', handleClick);
    });
}

// setConfigureChartbyCheckboxes();
