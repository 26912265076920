import {signOut} from "./auth_logout";

console.log("auth_login.js loaded...");

import { Auth } from 'aws-amplify';
import {save_analytics_event, setup_user_analytics} from "../app/analytics/analytics";
// Sign In function
export const signIn = async ({email, password}) => {
    try {
        const { user } = await Auth.signIn(email, password);
        localStorage.setItem('view', 'all_platforms');
        const session_id = (Math.random() + 1).toString(36);
        localStorage.setItem("session", session_id);
        await save_analytics_event({name: 'user_login', attributes: {
                type: 'login',
                session: session_id
            }}, true);
        await setup_user_analytics();
        window.location = 'app.html'
    } catch (error) {
        console.log(error);
        document.getElementById("btnLogin").style.display="inline-flex";
        document.getElementById("btn-loading").style.display="none";
        console.log('error signing in', error);
        alert(error.message);
        // window.location = '/login.html'
    }
}



// Event Listeners if user is on Login page
if (document.querySelector("#auth-login")) {
    checkAuth()
    document.querySelector("#form-auth-login").addEventListener("click", event => {
        event.preventDefault();
    });

    document.querySelector("#btnLogin").addEventListener("click", () => {
        document.getElementById("btnLogin").style.display="none";
        document.getElementById("btn-loading").style.display="inline-flex";
        const email = document.querySelector("#formLoginEmail").value
        const password = document.querySelector("#formLoginPassword").value
        signIn({email, password});
    });
};

function checkAuth() {
    console.log(window.location)
    Auth.currentAuthenticatedUser()
        .then(() => {
            // window.location = 'transcriptomics/index.html'
        })
        .catch(() => {
            // User is not logged in
        });
}

