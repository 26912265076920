import { all_platforms_view } from '../../dom.js';
import {ncbiGeneWorker, studyWorker, platformWorker, pubmedWorker} from "../../workers";
import {create_all_platforms_table} from "./all-platforms-table";

var show = 0;
export function initialize_all_platforms_view(){
    // reset the show variable to 0
    show = 0;
    // spawn worker job to get the ncbi genes data
    ncbiGeneWorker.postMessage({method: "SETUP"});
    // spawn worker job to get the studies data
    studyWorker.postMessage({method:  "INSTALL"});
    // spawn worker job to get the platforms data
    platformWorker.postMessage({method:  "GET"});
    // spawn worker job to load the pubmed data
    pubmedWorker.postMessage({method:  "INSTALL"});
}

platformWorker.addEventListener("message", function handleMessageFromWorker(msg) {
    create_all_platforms_table(msg.data);
    if(localStorage.view === 'all_platforms'){
        setup_all_platforms_view();
    }

})

ncbiGeneWorker.addEventListener("message", function handleMessageFromWorker(msg) {
    if(localStorage.view === 'all_platforms'){
        setup_all_platforms_view();
    }
});

studyWorker.addEventListener("message", function handleMessageFromWorker(msg) {
    if(localStorage.view === 'all_platforms'){
        setup_all_platforms_view();
    }
})

export function setup_all_platforms_view(){
    show += 1;
    if(show === 3){
        all_platforms_view();
    }
}

