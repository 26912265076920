import {update_plot} from './plotly-chart.js';
import {get, set} from '../index_db.js';
import {externally_update_radio_button} from "./orderby-checkboxes";
var initial_keys = {};
var current_groupings = [];


var handleClick = async function(radio){
    console.log('radio cliick in color by')
    console.log(radio.target);
    console.log(radio.target.value);
    console.log(radio.target.checked);
    var current_orderings = await externally_update_radio_button(radio)
    if(!current_groupings.includes(radio.target.value)){
        current_groupings.push(radio.target.value)
    }else{
         current_groupings = current_groupings.filter(a => a !== radio.target.value)
    }
    if(current_groupings.length===0){
        update_plot('grouping', false);
    }else if(current_groupings.length===1){
        update_plot('grouping', current_groupings[0]);
    }else{
        update_plot('grouping', current_groupings);
    }
}

// build the color by checkboxes
export async function  setupColorByCheckboxes(clear){
    if(clear === true){
        current_groupings = [];
    }
    var study = JSON.parse(await get('study'));
    initial_keys = {};
    study.samples.map(sample => { _.mapObject(sample,  function(val, key) {initial_keys[key]=1})})
    initial_keys = Object.keys(initial_keys);
    var container = document.getElementById('checkboxesColorby');
    if(container){
        container.innerHTML='';
    }
    for (var value of initial_keys) {
        if (['Accession', 'scanDates', 'scanTimes', 'md5', 'lastUpdate', 'species', 'studies','Platform', 'column', 'visible'].includes(value)){
            continue;
        }
        var myEle = document.getElementById('colorby-'+value);
        if(!myEle){
            if(_.indexOf(current_groupings, value) == -1){
                $('#checkboxesColorby').append(`<div class="form-check" id="colorby-${value}+1">`)
                .append(`<input class="form-check-input" type="checkbox" id="colorby-${value}" name="colorbyRadios" value="${value}">`)
                .append(`<label id="colorby-${value}-label" class="form-check-label" for="${value}">${value}</label></div>`)
                .append(`</div>`)
            }else{
                $('#checkboxesColorby').append(`<div class="form-check" id="colorby-${value}+1">`)
                .append(`<input class="form-check-input" type="checkbox" id="colorby-${value}" name="colorbyRadios" value="${value}" checked>`)
                .append(`<label id="colorby-${value}-label" class="form-check-label" for="${value}">${value}</label></div>`)
                .append(`</div>`)

            }
        }
    }
    document.querySelectorAll("input[name='colorbyRadios']").forEach((input) => {
        input.addEventListener('change', handleClick);
    });
}

