import { one_platform_view } from '../../dom.js';
import { del } from '../../util/index_db.js'
import {reset_plot} from "../../util/plot-components/plotly-chart.js";
import {samplesWorker, studyWorker, ncbiGeneWorker} from "../../workers";
import {study_listener} from "./studies-table/studies-table";
import {typeahead_listener} from "../../typeahead/typeahead-input-loader";
import {samples_worker_listener} from "./scatter-chart/coordinator";

var show = 0;


export function initialize_one_platform_view(){
    // reset the plot
    reset_plot()
    // reset the study
    del('study');
    var platform = JSON.parse(localStorage.getItem('platform'));
    // reset the show variable to 0
    show = 0;
    // spawn worker job to get the studies for this platform.
    // the listener is located in the studies-table.js file
    studyWorker.postMessage({method:  "GET_PLATFORM", query:{"Platform":platform}});
    // spawn worker job to get the samples data for this platform
    // the listener is located below.  it kicks off another worker
    // job that gets the samples information for the platform.
    samplesWorker.postMessage({method:  "INSTALL", query:{"Platform":platform}});
    // spawn worker to get genes covered by this platform
    // the listener is in the typeahead-input-loader.js file
    ncbiGeneWorker.postMessage({method: "GET_PLATFORM", query:{"Platform": platform}});
}

studyWorker.addEventListener("message", function handleMessageFromWorker(msg) {
    study_listener(msg);
});

ncbiGeneWorker.addEventListener("message", function handleMessageFromWorker(msg) {
    typeahead_listener(msg)
});

samplesWorker.addEventListener("message", function handleMessageFromWorker(msg) {
    if(localStorage.view === 'one_platform') {
        var platform = JSON.parse(localStorage.getItem('platform'));
        if (msg.data.msg) {
            switch(msg.data.msg.method ){
                case 'INSTALL':
                    samplesWorker.postMessage({method: "GET_ALL", query: {"Platform": platform}});
                    break;
                case 'GET_ALL':
                    samples_worker_listener(msg)
                    break;
                case 'other':
                    break;
            }
        }
    }
});

export function setup_one_platform_view(){
    show += 1;
    if(show === 3){
        one_platform_view();
    }
}

