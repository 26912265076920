import {update_plot} from "./plotly-chart.js";
import {get, set} from '../index_db.js';
var initial_keys = {};
var current_orderings = [];


export async function externally_update_radio_button(radio){
    var current_ordering = [];
    radio.target.value = radio.target.value.replace('colorby', 'orderby')
    document.querySelectorAll("input[name='orderbyRadios']").forEach((input) => {
        if(input.id === 'orderby-'.concat(radio.target.value)){
            if(radio.target.checked===true){
                input.checked = true;
            }else if(input.checked===true){
                input.checked = false;
            }
        }
        if(input.checked){
            current_ordering.push(input.value);
        }
    });
    var current_orderings   = await handleClick(radio, false);
    return current_orderings;
}

var handleClick = async function(radio, plot=true){
    if(!current_orderings.includes(radio.target.value)){
        if(radio.target.checked===true){
            current_orderings.push(radio.target.value);
        }
    }else{
         current_orderings = current_orderings.filter(a => a !== radio.target.value);
    }
    if(current_orderings.length===0){
        await update_plot('orderby', false, plot);
    }else if(current_orderings.length===1){
        await update_plot('orderby', current_orderings[0], plot);
    }else{
        await update_plot('orderby', current_orderings, plot);
    }
    return current_orderings;
}

// build the color by checkboxes
export async function setuporderbyCheckboxes(clear){
    if(clear === true){
        current_orderings = [];
    }
    var study = JSON.parse(await get('study'));
    initial_keys = {};
    study.samples.map(sample => { _.mapObject(sample,  function(val, key) {initial_keys[key]=1})})
    initial_keys = Object.keys(initial_keys);
    var selected = localStorage.getItem('current_ordering');
    var container = document.getElementById('checkboxesOrderBy');
    if(container){
        container.innerHTML='';
    }
    for (var value of initial_keys) {
        if (['Accession', 'scanDates', 'scanTimes', 'md5', 'lastUpdate', 'species', 'studies','Platform', 'column','visible'].includes(value)){
            continue;
        }
        var myEle = document.getElementById('orderby-'+value);
        if(!myEle){
            if(_.indexOf(current_orderings, value) == -1) {
                $('#checkboxesOrderBy').append(`<div class="form-check" id="orderby-${value}+1">`)
                    .append(`<input class="form-check-input" type="checkbox" id="orderby-${value}" name="orderbyRadios" value="${value}">`)
                    .append(`<label id="orderby-${value}-label" class="form-check-label" for="${value}">${value}</label></div>`)
                    .append(`</div>`)
            }else{
                $('#checkboxesOrderBy').append(`<div class="form-check" id="orderby-${value}+1">`)
                    .append(`<input class="form-check-input" type="checkbox" id="orderby-${value}" name="orderbyRadios" value="${value}" checked>`)
                    .append(`<label id="orderby-${value}-label" class="form-check-label" for="${value}">${value}</label></div>`)
                    .append(`</div>`)
            }
        }
    }
    document.querySelectorAll("input[name='orderbyRadios']").forEach((input) => {
        input.addEventListener('change', handleClick);
    });
}