import {get, set} from '../../util/index_db.js';
import {initialize_search_table} from "./study_annotation_table";
import {save_analytics_event} from "../../analytics/analytics";
var initial_keys = []
var study;
export async function initialize_annotation_table(){
    study = JSON.parse(await get('study'));
    initial_keys = {};
    study.samples.map(sample => { _.mapObject(sample,  function(val, key) {initial_keys[key]=1}) })
    initial_keys = Object.keys(initial_keys);
    sample_table(study.samples);
    initialize_search_table();
}


var table2 = false;
var select = false;
var sample_table = function(samples){
    if(table2 !== false){
        console.log('destroying');
        table2.clear();
        table2.destroy();
        $('#studyAnnotation').html('<table className="table table-sm table-hover cards"></table>');
    }
    var column_names = {};
    samples.map(sample => {
        delete sample['scanDates'];
        delete sample['scanTimes'];
        delete sample['species'];
        delete sample['md5'];
        delete sample['platform'];
        delete sample['ae_accession'];
        delete sample['column'];
        delete sample['lastUpdate'];
        delete sample['studies'];
        // replaces '.' with '_' in column names
        _.mapObject(sample, function(val, key){
            if(key.indexOf('.') !== -1){
                var new_key = key.replaceAll(".", "_");
                sample[new_key] = val;
                delete sample[key];
                key = new_key;
            }
            column_names[key] = 1;
        })
    })
    column_names = Object.keys(column_names);
    samples.map(sample => {
        column_names.map(column_name => {
            if(!(column_name in sample)){
                sample[column_name] = null;
            }
        })
    })
    var columns = [
        {data: "Accession", name: "Accession", title: "Accession", searchable: false},
    ];
    column_names.map(column_name => {
        if(column_name !== 'Accession' && column_name !== 'data' && column_name !== 'visible'){
            columns.push({data: column_name, name: column_name, title: column_name});
        }
    });
    table2 = $('#studyAnnotation').DataTable({
        buttons: [
            {
                extend: 'collection',
                text: 'Download',
                className: 'btn btn-outline-primary',
                buttons: [
                    {
                        extend: 'csvHtml5',
                        className: 'btn btn-outline-primary',
                        text: "CSV",
                        filename: study.Accession,
                        customize: function(csv, config, table){
                            save_analytics_event({name: 'download_study', attributes: {
                                    type: 'download',
                                    format: 'csv',
                                    filename: localStorage.study_accession
                                }});

                        }
                    },
                    {
                        extend: 'copyHtml5',
                        className: 'btn btn-outline-primary',
                        customize: function (e, dt, button, config) {
                            save_analytics_event({
                                name: 'copy_study', attributes: {
                                    type: 'copy',
                                    format: 'clipboard',
                                    filename: localStorage.study_accession
                                }
                            });
                        }
                    },
                    {
                        extend: 'excelHtml5',
                        className: 'btn btn-outline-primary',
                    },
                    {
                        text: 'JSON',
                        className: 'btn btn-outline-primary',
                        filename: localStorage.study_accession,
                        action: function (e, dt, button, config) {
                            var data = dt.buttons.exportData();

                            $.fn.dataTable.fileSave(
                                new Blob([JSON.stringify(data)]),
                                'Export.json'
                            );
                            save_analytics_event({name: 'download_study', attributes: {
                                    type: 'download',
                                    format: 'json',
                                    filename: localStorage.study_accession
                                }});
                        }
                    }]
            }
        ],
        dom: 'Bfrtip',
        data: samples,
        pageLength:100,
        retrieve:true,
        select:true,
        fixedHeader: false,
        order: [[1, 'desc']],
        columns: columns
    });
    if(select === false){
        select = true;
        table2.on('select', function (e, dt, type, indexes) {
            console.log("select is happening in the sample annotation table");
            console.log(indexes);
            console.log(table2);
            console.log(dt);
        });
    }
    console.log('hey');
}
