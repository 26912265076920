import {save_analytics_event} from "../app/analytics/analytics";

console.log("auth_logout.js loaded...");

import { Auth } from 'aws-amplify';

// Sign Out function
export async function signOut() {
    console.log("signOut triggered...")
    try {
        await save_analytics_event({name: 'user_logout', attributes: {
                type: 'logout',
                session: localStorage.session
            }}, true);
        await Auth.userPool.getCurrentUser().signOut()
        localStorage.setItem('view', 'all_platforms');
        localStorage.setItem('session', 'none');
        window.location = '/index.html'
    } catch (error) {
        console.log('error signing out: ', error);
    }
}


// Event Listener for Sign Out button
if (document.querySelector("#nav-logout")) {
    document.querySelector("#nav-logout").addEventListener("click", () => {
        signOut();
    })
}