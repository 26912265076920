export function set_show_hide() {
    document.getElementById("show_hide_accordions").onclick = function(event) {
        var value = document.getElementById("show_hide_accordions").innerHTML;
        if (value === 'hide'){
            document.getElementById("show_hide_accordions").innerHTML = 'show';
            document.getElementById("plot-div").classList.remove('col-md-8');
            document.getElementById("accordion-div").style.display = 'none';
            document.getElementById("plot-div").classList.add('col-md-12');
        }else{
            document.getElementById("plot-div").classList.remove('col-md-12');
            document.getElementById("plot-div").classList.add('col-md-8');
            document.getElementById("show_hide_accordions").innerHTML = 'hide';
            document.getElementById("accordion-div").style.display = 'block'
        }
        const view = localStorage.getItem('view');
        switch(view){
            case 'all_platforms':
                break;
            case 'one_platform':
                var graphDiv = document.getElementById('one-platform-chart');
                Plotly.newPlot(graphDiv, graphDiv.data, graphDiv.layout);
                break;
            case 'study_view':
                var graphDiv = document.getElementById('study-view-chart');
                Plotly.newPlot(graphDiv, graphDiv.data, graphDiv.layout);
                break;
        }
        event.preventDefault();
    }
}
