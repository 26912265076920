// When user clicks Home breadcrumb button.
import {get, set, del} from './index_db.js';
import {layout, toggle_x_axis} from "../dom.js";
import {reset_plot, remove_study} from "./plot-components/plotly-chart";
import {update_state} from "../analytics/url-manager";
import {purge_platform_plot} from "../views/one-platform/scatter-chart/coordinator";
var platform;

export function set_platform_breadcrumb_one_platform() {
    platform = JSON.parse(localStorage.getItem('platform'));
    document.getElementById("breadcrumb-allstudies").innerHTML = platform.Platform;
    document.getElementById("breadcrumb-allplatforms").onclick = function(event) {
        layout({'data': {view: 'all_platforms'}})
        event.preventDefault();
        purge_platform_plot()
    }
}
export async function set_platform_breadcrumb_one_study() {
    platform = JSON.parse(localStorage.getItem('platform'));
    document.getElementById("breadcrumb-allstudies").innerHTML = '<a href="#">' + platform.Platform + '</a>';
    document.getElementById("breadcrumb-allplatforms").onclick = function() {
        layout({'data': {view: 'all_platforms'}})
        purge_platform_plot()
    }
    var study = JSON.parse(await get('study'));
    document.getElementById("breadcrumb-studyid").innerHTML = study.Accession;
    document.getElementById("breadcrumb-allstudies").onclick = function(event) {
        // bc_dom_manager.postMessage({view: 'one_platform'});
        reset_plot()
        remove_study().then(x => {
            $("#accordionConfigureChart").hide();
            $("#accordionColorby").hide();
            $("#accordionOrderBy").hide();
            $("#annotater").hide();
            $("#study-view-chart").hide();
            $("#one-platform-chart").show();
            $("#study-info").hide();
            $("#all_studies").show();
            $("#breadcrumb-studyid").hide();
            $("#platform_or_study").show();
            $("#help-icon-study").hide();
            $("#help-icon-studies").show();
            $("#help-icon-platform").hide();
            del('study');
            set_platform_breadcrumb_one_platform();
            toggle_x_axis(true);
            localStorage.setItem('view', 'one_platform');
            update_state();
        })
        event.preventDefault();
    }
}




