import {get} from "../../util/index_db";

var pubmed_data = []

export async function searchGEO(query) {
    query = 'GSE40151'
    const url = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=gds&term=${query}+AND+GSE[entrytype]&retmode=json`;
    return fetch(url)
        .then(response => response.json())
        .then(data => {
            const ids = data.esearchresult.idlist;
            const summaryUrl = `https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=gds&id=${ids.join(",")}&retmode=json`;
            return fetch(summaryUrl);
        })
        .then(response => response.json())
        .then(data => {
            console.log('geo query data.result');
            console.log(data.result);
        })
        .catch(error => console.error(error));
}


export async function pubmed_listener(msg){
    if(msg.data.msg.method=='GET_STUDY'){
        pubmed_data = msg.data.value;
        pubmed_table();
    }
}


var table2 = false;
var select = false;
var pubmed_table = function(){
    if(table2 !== false){
        table2.clear();
        table2.destroy();
        $('#pubmedPapers').html('<table className="table table-sm table-hover cards" style="cursor: pointer"></table>');
    }
    var columns = [
        {data: "journal", name: "journal", title: "Journal", searchable: true},
        {data: "title", name: "title", title: "Title", searchable: true},
    ];
    table2 = $('#pubmedPapers').DataTable({
        data: pubmed_data,
        pageLength:100,
        retrieve:true,
        select:true,
        fixedHeader: false,
        buttons: ['copy'],
        order: [[1, 'desc']],
        columns: columns
    });
    if(select === false){
        select = true;
        table2.on('select', function (e, dt, type, indexes) {
            var url = 'https://pubmed.ncbi.nlm.nih.gov/'+ pubmed_data[indexes]['id'] + '/';
            window.open(url)
        });
    }
}
