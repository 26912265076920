import {save_analytics_event} from "../app/analytics/analytics";

console.log("auth_forgot_password.js loaded...");

import { Auth } from 'aws-amplify';

// Forgot password function
export const forgotPass = async ({username}) => {
    try {
        const { user } = await Auth.forgotPassword(username);
        console.log(user)
        save_analytics_event({name: 'forgot_password', attributes: {
                type: 'forgot_password',
                email: username
            }});
        window.location = '/forgot_confirm.html#' + username;
    } catch (error) {
        document.getElementById("btnForgot").style.display="inline-flex";
        document.getElementById("btn-loading").style.display="none";
        console.log('error signing in', error);
        alert(error.message);
        //window.location = '/index.html'
    }
}

// Event Listeners if user is on Forgot Password page
if (document.querySelector("#auth-forgot-password")) {

    document.querySelector("#form-auth-forgot-password").addEventListener("click", event => {
        event.preventDefault();
    });

    document.querySelector("#btnForgot").addEventListener("click", () => {
        document.getElementById("btnForgot").style.display="none";
        document.getElementById("btn-loading").style.display="inline-flex";
        const username = document.querySelector("#formForgotEmail").value
        forgotPass( {username});
    });

}

// Confirm New Password function
export const confirmForgotPass = async (username, code, new_password) => {
    try {
        await Auth.forgotPasswordSubmit(username, code, new_password);
        alert("New password confirmation sent");
        save_analytics_event({name: 'forgot_password_confirmation_sent', attributes: {
                type: 'forgot_password_confirmation_sent',
                email: username
            }});
        window.location = '/index.html'
    } catch (error) {
        document.getElementById("btnConfirmForgot").style.display="inline-flex";
        document.getElementById("btn-loading").style.display="none";
        console.log('error confirming new password', error);
        alert(error.message);
    }
}

// Event Listeners on the Confirm New Password page (after Forgot Password page)
if (document.querySelector("#auth-forgot-password-confirm")) {

    // Populate the email address value
    let username_value = location.hash.substring(1);
    document.querySelector("#formForgotConfirmEmail").setAttribute("value", username_value);


    document.querySelector("#form-auth-forgot-password-confirm").addEventListener("click", event => {
        event.preventDefault();
    });

    document.querySelector("#btnConfirmForgot").addEventListener("click", () => {
        document.getElementById("btnConfirmForgot").style.display="none";
        document.getElementById("btn-loading").style.display="inline-flex";

        const username = document.querySelector("#formForgotConfirmEmail").value
        let code = document.querySelector("#formForgotConfirmCode").value
        let password = document.querySelector("#formForgotConfirmPassword").value
        confirmForgotPass( username, code, password );
    });

}
