import {get, set} from '../../util/index_db.js';
import {refresh_plot, update_chart_data} from "../../util/plot-components/plotly-chart";
import {save_analytics_event} from "../../analytics/analytics";
var initial_keys = []
var study;
export async function initialize_search_table(){
    study = JSON.parse(await get('study'));
    initial_keys = {};
    study.samples.map(sample => { _.mapObject(sample,  function(val, key) {initial_keys[key]=1}) })
    initial_keys = Object.keys(initial_keys);
    sample_table(study.samples);
}


var table2 = false;
var select = false;
var sample_table = function(samples){
    if(table2 !== false){
        console.log('destroying');
        table2.clear();
        table2.destroy();
        table2.searchPanes.clearSelections();
        $('#studySearch').html('<table className="table table-sm table-hover cards"></table>');
    }
    var column_names = {};
    samples.map(sample => {
        delete sample['scanDates'];
        delete sample['scanTimes'];
        delete sample['species'];
        delete sample['md5'];
        delete sample['platform'];
        delete sample['ae_accession'];
        delete sample['column'];
        delete sample['lastUpdate'];
        delete sample['studies'];
        // replaces '.' with '_' in column names
        _.mapObject(sample, function(val, key){
            if(key.indexOf('.') !== -1){
                var new_key = key.replaceAll(".", "_");
                sample[new_key] = val;
                delete sample[key];
                key = new_key;
            }
            column_names[key] = 1;
        })
    })
    column_names = Object.keys(column_names);
    samples.map(sample => {
        column_names.map(column_name => {
            if(!(column_name in sample)){
                sample[column_name] = null;
            }
        })
    })
    var columns = [
        {data: "Accession", name: "Accession", title: "Accession", searchable: false},
    ];
    column_names.map(column_name => {
        if(column_name !== 'Accession' && column_name !== 'data' && column_name !== 'visible'){
            columns.push({data: column_name, name: column_name, title: column_name});
        }
    });
    table2 = $('#studySearch').DataTable({
        buttons: [
            {
                extend: 'collection',
                text: 'Download',
                className: 'btn btn-outline-primary',
                buttons: [
                    {
                        extend: 'csvHtml5',
                        className: 'btn btn-outline-primary',
                        text: "CSV",
                        filename: study.Accession,
                        customize: function(csv, config, table){
                            save_analytics_event({name: 'download_study', attributes: {
                                    type: 'download',
                                    format: 'csv',
                                    filename: localStorage.study_accession
                                }});
                        }
                    },
                    {
                        extend: 'copyHtml5',
                        className: 'btn btn-outline-primary',
                        customize: function (e, dt, button, config) {
                            save_analytics_event({
                                name: 'copy_study', attributes: {
                                    type: 'copy',
                                    format: 'clipboard',
                                    filename: localStorage.study_accession
                                }
                            });
                        }
                    },
                    {
                        extend: 'excelHtml5',
                        className: 'btn btn-outline-primary',
                    },
                    {
                        text: 'JSON',
                        className: 'btn btn-outline-primary',
                        filename: localStorage.study_accession,
                        action: function (e, dt, button, config) {
                            var data = dt.buttons.exportData();

                            $.fn.dataTable.fileSave(
                                new Blob([JSON.stringify(data)]),
                                'Export.json'
                            );
                            save_analytics_event({name: 'download_study', attributes: {
                                    type: 'download',
                                    format: 'json',
                                    filename: localStorage.study_accession
                                }});
                        }
                    }]
            }
        ],
        searchPanes: {
            layout: 'columns-4',
            cascadePanes: false
        },
        dom: 'PBfrtip',
        data: samples,
        pageLength:100,
        retrieve:true,
        select:true,
        fixedHeader: false,
        order: [[1, 'desc']],
        columns: columns
    });
    if(select === false){
        select = true;
        table2.on('search.dt', function (e, dt, type, indexes) {
            console.log("select is happening in the sample annotation table");
            console.log(indexes);
            console.log(table2);
            console.log(dt);
            console.log(e);
            var rows = table2.rows( {search: 'applied'} ).data().toArray();
            console.log(rows);
            if(rows.length===0){
                return;
            }
            get('study').then((study) =>{
                var study = JSON.parse(study);
                var gsms = _.pluck(rows,"Accession")
                var result = Object.fromEntries(gsms.map(k => [k, 1]))
                study.samples.map((sample) =>{
                    sample.visible = false;
                    if(result[sample.Accession] !== undefined){
                        sample.visible = true;
                    }
                });
                set('study', JSON.stringify(study));
                console.log('just redrew study');
                console.log(study);
                refresh_plot();
            })
        });
    }
    // table2.on( 'draw', function () {
    //     console.log( 'Redraw occurred at: '+new Date().getTime() );
    // } );


    // table2.on( 'search.dt').off().on('select.dt', function ( e, dt, type, indexes ) {
    //     var selected = dt.rows(indexes).data();
    //     console.log('selected');
    //     console.log(selected);
    //     return;
    //     var rows = table2.rows( {search: 'applied'} ).data().toArray();
    //     update_chart_data(rows);
    //     console.log('rows.length');
    //     console.log(rows.length);
    // //     console.log('done with the search')
    // //     console.log(rows);
    // //     console.log(e);
    // //     console.log(settings)
    // } );
    console.log('hey');
}
