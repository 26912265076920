import { Analytics } from 'aws-amplify';
import {userAuthState} from "../../auth/auth_user";
import {get} from "../util/index_db";
var user;

export function setup_analytics(){
    Analytics.record({ name: 'pageview' });
}

hotkeys('ctrl+u', function(event,handler) {
    switch(handler.key) {
        case "ctrl+u":
            console.log(user)
            break;
    }
})


async function get_event(item){
    const view = localStorage.getItem('view');
    if(view === 'study_view') {
        var study = JSON.parse(await get('study'));
        item.study = study.Accession;
    }
    return item
}

export function setup_user_analytics(){
    userAuthState()
        .then(data => {
            user = data;
            delete user.attributes.email_verified;
            delete user.attributes.sub;
        })
        .catch(error => {
            // Since this is a secret page and the user is not authenticated, redirect to the login page.
            // alert("This user is not authenticated and will be redirected");
            // window.location = '/index.html';
            user = {
                'attributes': {
                    'unauthenticated': true
                }
            }
        });
}


export async function save_analytics_event(event, immediate=false){
    console.log('saving');
    if(!('attributes' in event)){
        event.attributes = {};
    }
    event.attributes.session = localStorage.getItem('session');
    event.attributes.view = localStorage.getItem('view');
    event.attributes = await get_event(event.attributes);
    if (event.name === 'gene_view'){
        delete event.attributes.gene_id;
        delete event.attributes.name;
    }
    Object.assign(event.attributes, user.attributes, event.attributes);
    if(immediate == true){
        event.immediate = immediate;
        await Analytics.record(event);
    }else{
        Analytics.record(event);
    }
}

setup_user_analytics()