import { add_grouping, get_order } from './plot-utils.js';
import {get, set} from '../index_db.js';
import {hide_non_visible_samples} from "../study";
var plotly_type = 'scattergl';




const bc_chart = new BroadcastChannel("chart");
bc_chart.onmessage = (event) => {
    if(event.data.type === 'configurechart' && event.data.plotly_type=='scattergl'){
        plotly_type = event.data.plotly_type;
        var link = document.getElementById('gene_2_form_input');
        link.style.display = 'flex';
        link = document.getElementById('accordionOrderBy');
        link.style.display = 'none';
    }
}


export var bg_trace_scatter = function(type){
    return {
        type: "scattergl",
        mode: "markers",
        opacity: 0.75,
        marker: {
            color: '#bec0bf',
            size: 3,
            },
        x: [],
        y: []
    }
}

export var blank_trace_scatter = function(type){
    return {
        type: "scattergl",
        mode: "markers",
        marker: {
            color: '#2e5984',
            size: 4,
            opacity: 0.5
        },
        x: [],
        y: []
    }
}

export var multi_trace_scatter = function(type){
    return {
        type: "scattergl",
        mode: "markers",
        marker: {
            width: 3,
        },
        x: [],
        y: []
    }
}


export var get_traces_scatter = async function(x,y, plotly_type, grouping, show_all, is_platform){
    if(grouping === false && is_platform === true) {
        return one_trace_scatter(x,y,plotly_type,show_all);
    }
    var study;
    if(localStorage.view === 'study_view'){
        study = await hide_non_visible_samples(x,y);
        console.log("back from study_view")
        console.log(study);
        x.data = study.x;
        y.data = study.y;
    }
    if(grouping === false) {
        return one_trace_scatter(x,y,plotly_type,show_all);
    }

    var groups = {};
    var grouping_key = grouping;
    if(!_.isString(grouping)){
        grouping_key = grouping.join(",")
        // adds an entry to every sample consisting of the values for each group variable
        add_grouping(grouping, study);
    }
    for(var i=0; i < x.data.length; i++){
        study.samples[i]['x'] = x.data[i];
        study.samples[i]['y'] = y.data[i];
    }
    study.samples.map(sample => {
        if(grouping_key in sample){
            if(!(sample[grouping_key] in groups)){
                groups[sample[grouping_key]] = multi_trace_scatter(plotly_type);
                groups[sample[grouping_key]]['name'] = sample[grouping_key];
            }
            groups[sample[grouping_key]]['x'].push(sample.x);
            groups[sample[grouping_key]]['y'].push(sample.y);
        }
    });
    if(show_all === true){
        var bg_trace = bg_trace_scatter(plotly_type);
        bg_trace['x'] = x.all_data;
        bg_trace['y'] = y.all_data;
        var platform = localStorage.getItem('platform');
        bg_trace['name'] = platform;
    }
    return Object.values(groups);
}

export var one_trace_scatter = function(x,y,plotly_type,show_all){
    var groups = {};
    var study = {
        samples: []
    };
    groups['all'] = blank_trace_scatter(plotly_type);
    for(var i=0; i < x.data.length; i++){
        study.samples[i] = {};
        study.samples[i]['x'] = x.data[i];
        study.samples[i]['y'] = y.data[i];
        groups['all']['x'].push(x.data[i]);
        groups['all']['y'].push(y.data[i]);
    }
    groups['all']['name'] = 'Study';
    return [groups['all']];
}

export var scatter_lasso = function(x,y){
    const view = localStorage.view;
    var title = ''
    switch(view){
        case 'one_platform':
            var platform = JSON.parse(localStorage.getItem('platform'));
            title = platform.Platform;
            break
        case 'study_view':
            title = localStorage.getItem('study_accession');
            break;
    }
    var lasso = {
        dragmode: 'lasso',
        hoverinfo: 'none',
        autosize: true,
        title:title,
        showlegend: true,
        legend: {
            x: 1,
            y: 0
        },
        margin: {
            "b":50,
            "l":50,
            "r":0,
            "t":30,
        },

        xaxis: {
            range: [4,17],
            title: {
                text: x.gene.Symbol,
                font: {
                    family: 'Courier New, monospace',
                    size: 20,
                    color: '#000000'
                }
            },
            zeroline: false,
        },
        yaxis: {
            range: [4,17],
            title: {
                text: y.gene.Symbol,
                font: {
                    family: 'Courier New, monospace',
                    size: 26,
                    color: '#000000'
                }
            },
            zeroline: false,
        }
    };
    return lasso;
}
