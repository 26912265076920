import { setup_one_platform_view } from '../one-platform-view.js';
import {get, set} from '../../../util/index_db.js';
import {studyWorker, pubmedWorker} from "../../../workers";
import {layout, one_platform_view, spinner} from "../../../dom";
import {pubmed_listener, searchGEO} from "../../one-study-view/pubmed_table";
import {get_study_json, updateStudy} from "../../one-study-view/get-study-json";
import {add_mv_to_table} from "./genes_mv"
import {save_analytics_event} from "../../../analytics/analytics";
import {update_state} from "../../../analytics/url-manager";

var studies;
var studies_d = {}
var current_selection;
var server_json;
var platform;
export function study_listener(msg){
    if(msg.data.msg.method==='GET_PLATFORM'){
        platform = msg.data.msg.query.Platform;
        studies = msg.data.value;
        studies = msg.data.value.map((obj) => {
            obj.num_selected = 0;
            if (obj.Accession !== undefined) return obj;
        });
        studies_d = _.indexBy(studies, 'Accession')
        current_selection = undefined;
        create_study_table();
        setup_one_platform_view();
    }
}

export function setSelectedStudies(selection){
    current_selection = selection;
}

var table1 = false;
export async function create_study_table(){
    $("#studies-table-content").hide()

    var columns = [
        { title: 'Accession', data:'Accession', width: "10%"},
        { title: 'Num Selected', data:'num_selected', width: "10%", visible: true, "orderSequence": [ "desc", "asc"]},
        { title: 'Total', data:'num_samples', width: "10%", "orderSequence": [ "desc", "asc"]},
        { title: 'Mean', data:'mean',  width: "5%", "orderSequence": [ "desc", "asc"]},
        { title: 'Stdev', data:'stdev',  width: "5%", "orderSequence": [ "desc", "asc"] },
        { title: 'Mean2', data:'mean2',  width: "5%", "orderSequence": [ "desc", "asc"] },
        { title: 'Stdev2', data:'stdev2',  width: "5%", "orderSequence": [ "desc", "asc"] },
        { title: 'Title', data:'attributes.title',  width: "50%" },
    ];
    var order = [[4, 'desc']]
    if(table1 !== false){
        table1.clear();
        table1.destroy();
        $('#studyTable').html('<table className="table table-responsive"></table>');
    }
    var studies2 = {};
    if(current_selection !== undefined){
        var subset = {};
        _.map(current_selection, function(num_selected, study_accession){
            if(studies_d[study_accession] !== undefined){
                subset[study_accession] = studies_d[study_accession];
                subset[study_accession].num_selected = num_selected;
            }
        });
        studies2 = Object.values(subset);
        order = [[1, 'desc']]
    }else{
        studies2 = JSON.parse(JSON.stringify(studies));
        columns[1].data = 'num_samples';
    }
    var tmp = await add_mv_to_table(studies2)
    studies2 = tmp['studies'];
    var gene_1_mean_key = tmp['gene_1_mean_key'];
    var gene_2_mean_key = tmp['gene_2_mean_key'];
    var gene_1_std_key = tmp['gene_1_std_key'];
    var gene_2_std_key = tmp['gene_2_std_key'];
    columns[3]['data'] = gene_2_mean_key;
    columns[4]['data'] = gene_2_std_key;
    columns[3]['title'] = gene_2_mean_key;
    columns[4]['title'] = gene_2_std_key;
    columns[5]['data'] = gene_1_mean_key;
    columns[6]['data'] = gene_1_std_key;
    columns[5]['title'] = gene_1_mean_key;
    columns[6]['title'] = gene_1_std_key;
    $("#studies-table-content").show()
    table1 = $('#studyTable').DataTable({
        data: studies2,
        dom:'frtip',
        fixedHeader: false,
        pageLength: 100,
        order: order,
        retrieve: true,
        select: true,
        buttons: [{
            extend: 'collection',
            text: 'Download',
            className: 'btn btn-outline-primary',
            buttons:[
                {
                    extend: 'csvHtml5',
                    className: 'btn btn-outline-primary',
                    text: "CSV",
                    customize: function(csv, config, table){
                        console.log('firing for csv download');
                        console.log(csv);
                        console.log(config);
                        console.log(table);
                    }
                },
                {
                    extend: 'copyHtml5',
                    className: 'btn btn-outline-primary',
                },
                {
                    extend: 'excelHtml5',
                    className: 'btn btn-outline-primary',
                }
            ]
        }],
        columns: columns,
        // searchDelay: 500
    });
    $('#studyTable').off().on('select.dt', function ( e, dt, type, indexes ) {
        var study = dt.rows(indexes).data()[0];
        localStorage.setItem('current_grouping', false);
        localStorage.setItem('plotly_type','scattergl');
        localStorage.setItem('current_ordering', false);
        localStorage.setItem('study_accession', study.Accession);
        load__selected_study(study)
    });
}

pubmedWorker.addEventListener("message", function handleMessageFromWorker(msg) {
    pubmed_listener(msg);
})

export async function save_study(){
    var study = JSON.parse(await get('study'));
    db_samples.annotations.where('studies').equals( study.Accession).toArray().then( (samples) => {
        samples.map(s => {
            if (s['Accession'] in server_json['gsms']) {
                var new_fields = server_json['gsms'][s['Accession']]._tmx.attributes;
                server_json['gsms'][s['Accession']]._tmx.attributes = s
                Object.assign(s, new_fields, s);
            }
        });
        updateStudy(study.Accession, server_json, 'public')
    })
}

async function load__selected_study(study){
    document.getElementById('content-loading').innerText = 'Loading Study Data...'
    spinner()
    server_json = await get_study_json(study.Accession);
    if(server_json === undefined){
        save_analytics_event({
            name: 'study_view',
            attributes: {
                "Accession": study.Accession,
                'status': 'fail'
            }
        });
        one_platform_view();
        return;
    }
    // searchGEO(study.Accession);
    db_samples.annotations.where('studies').equals( study.Accession).toArray().then( (samples) =>{
        samples.map(s => {
            if(s['Accession'] in server_json['gsms']){
                var new_fields = server_json['gsms'][s['Accession']]._tmx.attributes;
                Object.assign(s, new_fields, s);
            }
            s['visible'] = true;
        });
        if(samples.length != 0){
            pubmedWorker.postMessage({method:  "GET_STUDY", 'Accession': study.Accession, 'study': study});
            samples = _.sortBy(samples, 'column');
            study.samples = samples;
            study.samples_dc = JSON.parse(JSON.stringify(samples));
            study.initial_keys = Object.keys(samples[0]);
            set('study', JSON.stringify(study));
            const save_in_db = localStorage.save_in_db;
            if(save_in_db==='1'){
                save_study()
                localStorage.setItem('save_in_db', false);
            }
            layout({'data': {view: 'study_view'}})
            save_analytics_event({name: 'study_view', attributes: {"Accession": study.Accession}});
            update_state(platform[0], '?platform='.concat(platform.Platform).concat('&study=').concat(study.Accession));
        }else{
            console.log('this should not be getting called');
            one_platform_view()
            save_analytics_event({name: 'study_view', attributes: {"Accession": study.Accession, 'status': 'fail'}});
            toastr.options = {"positionClass": "toast-top-full-width"};
            toastr.warning('Raw study data not available.')
        }
    });
}