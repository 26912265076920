export function checkStatus(response, event) {
    if (!response.ok) {
        postMessage(
            {
                'event': event.data,
                'status': response.status,
                'statusText': response.statusText
            }
        );
        throw new Error(`HTTP ${response.status} - ${response.statusText}`);
    }
    return response;
}