console.log("index.js started...");

import Amplify from "aws-amplify";
import { Auth } from 'aws-amplify';
import aws_exports from "./aws-exports.js";

import { userAuthState } from './js/auth/auth_user';
// import { addDocument } from './js/app/upload-doc';
import { checkAuthContent } from './js/auth/auth_content';
import { signUp, confirmSignUp, resendConfirmationCode } from './js/auth/auth_signup';
import { signIn } from './js/auth/auth_login';
import { forgotPass, confirmForgotPass } from './js/auth/auth_forgot_password';
import { signOut } from './js/auth/auth_logout';

import "./js/app/dom";
import "./js/app/util/help-content";
Amplify.configure(aws_exports);

// routes user to home page if they are not logged in and trying to access protected content
checkAuthContent();

console.log("index.js finished...");