

export var get_order = function(samples, current_ordering){
    console.log('in get order')
    console.log(current_ordering);
    console.log(current_ordering.length);
    console.log(samples);
    console.log(current_ordering);
    if(_.isString(current_ordering)){
        current_ordering = [current_ordering];
    }
    if(current_ordering !== false) {
        var comp = sortObjectsByKeys(samples, current_ordering)
        return;
    }
    // if(current_ordering === false) {
    //     return;
    // }else if(current_ordering.length == 1){
    //     samples.sort((a, b) => a[current_ordering[0]].localeCompare(b[current_ordering[0]]));
    // }else if(current_ordering.length == 2){
    //     samples.sort((a, b) => a[current_ordering[0]].localeCompare(b[current_ordering[0]]) || a[current_ordering[1]].localeCompare(b[current_ordering[1]]));
    // }else if(current_ordering.length == 3){
    //     samples.sort((a, b) => a[current_ordering[0]].localeCompare(b[current_ordering[0]]) || a[current_ordering[1]].localeCompare(b[current_ordering[1]]) || a[current_ordering[2]].localeCompare(b[current_ordering[2]]));
    // }
}

function try_to_convert_to_numeric(value){
    var x = +parseInt(value.replace("day",""))
    if(_.isNaN(x)){
        x = +parseInt(value.replace("week",""))
        if(_.isNaN(x)){
            return value;
        }else{
            return x;
        }
    }else{
        return x;
    }
}

function sortObjectsByKeys(objects, keys) {
    console.log("sorting keys");
    console.log(keys);
    return objects.sort((a, b) => {
        let comparison = 0;
        keys.forEach(key => {
            var type = 'string';
            if (comparison === 0) {
                var valueA = a[key];
                if(valueA === undefined){
                    valueA = 'No Data';
                }
                valueA = try_to_convert_to_numeric(valueA);
                // console.log(key);
                // console.log(valueA);
                var valueB = b[key];
                if(valueB === undefined){
                    valueB = 'No Data';
                }
                valueB = try_to_convert_to_numeric(valueB);
                if (typeof valueA === 'number' && typeof valueB === 'number') {
                    type = 'number';
                    comparison = valueA - valueB;
                } else if (typeof valueA === 'string' && typeof valueB === 'number') {
                    valueB = valueB.toString()
                    comparison = valueA.localeCompare(valueB);
                } else if (typeof valueA === 'number' && typeof valueB === 'string') {
                    valueA = valueA.toString()
                    comparison = valueA.localeCompare(valueB);
                }else{
                    comparison = valueA.localeCompare(valueB);
                }
            }
        });
        return comparison;
    });
}

export var add_grouping = function(grouping, study){
    var grouping_key = grouping.join(",");
    study.samples.map(sample => {
        var grouping_value='';
        grouping.map(group => {
            if(group in sample){
                grouping_value += sample[group] + '_';
            }
        })
        sample[grouping_key] = grouping_value
    })
}
