import {get, set} from "./index_db";

export async function hide_non_visible_samples(x_input, y_input){
    var study = JSON.parse(await get('study'));
    var samples = [];
    var x = [];
    var y = [];
    for(var i=0; i < study.samples.length; i++){
        if(study.samples[i].visible === true){
            samples.push(study.samples[i]);
            x.push(x_input.data[i]);
            y.push(y_input.data[i]);
        }
    }
    study.samples = samples;
    study.x = x;
    study.y = y;
    return study;
}

export async function reset_study_data(){
    var study = JSON.parse(await get('study'));
    study.samples.map((sample) => {sample.visible = true})
    var columns = _.pluck(study.samples, 'column');
    study.x = columns.map(column => x_axis.data[column]);
    study.y = columns.map(column => y_axis.data[column]);
    set('study', JSON.stringify(study));
}