import {get, set} from "../../../util/index_db";
import {checkStatus} from "../../../util/check-status";

export async function add_mv_to_table(studies){
    // adds the gene mean and variances to the studies table
    var platform;
    var gene_1;
    var gene_2;
    platform = JSON.parse(localStorage.getItem('platform'));
    gene_1 = JSON.parse(localStorage.getItem('gene_1'));
    gene_2 = JSON.parse(localStorage.getItem('gene_2'));
    if(gene_1 === null){
        switch(platform.Species){
            case 'human':
                gene_1={'value':'AR', 'gene_id': 367, 'name': 'androgen receptor'};
                gene_2={'value':'IGF1', 'gene_id': 3479, 'name': 'igf1'};
                break
            case 'mouse':
                gene_1={'value':'Ar', 'gene_id': 11835, 'name': 'androgen receptor'};
                gene_2={'value':'Igf1', 'gene_id': 16000, 'name': 'igf1'};
                break
            case 'rat':
                gene_1={'value':'Ar', 'gene_id': 24208, 'name': 'androgen receptor'};
                gene_2={'value':'Igf1', 'gene_id': 24482, 'name': 'igf1'};
                break
        }
    }
    var g2_study2sample = await get_mean_vars(platform, gene_2);
    var gene_2_mean_key = gene_2.value +' Mean'
    var gene_2_std_key = gene_2.value +' Std'
    var g1_study2sample = await get_mean_vars(platform, gene_1);
    var gene_1_mean_key = gene_1.value +' Mean'
    var gene_1_std_key = gene_1.value +' Std'
    for(var i=0; i < studies.length; i++){
        if(studies[i].Accession in g2_study2sample){
            studies[i][gene_2_mean_key] = g2_study2sample[studies[i].Accession].mean;
            studies[i][gene_2_std_key] = g2_study2sample[studies[i].Accession].stdev;
        }else{
            studies[i][gene_2_mean_key] = 0;
            studies[i][gene_2_std_key] = 0;
        }
        if(studies[i].Accession in g1_study2sample){
            studies[i][gene_1_mean_key] = g1_study2sample[studies[i].Accession].mean;
            studies[i][gene_1_std_key] = g1_study2sample[studies[i].Accession].stdev;
        }else{
            studies[i][gene_1_mean_key] = 0;
            studies[i][gene_1_std_key] = 0;
        }
    }
    return {
        'studies': studies,
        'gene_2_mean_key': gene_2_mean_key,
        'gene_2_std_key': gene_2_std_key,
        'gene_1_mean_key': gene_1_mean_key,
        'gene_1_std_key': gene_1_std_key
    }
}

export async function get_mean_vars(platform, gene) {
    // gets the means and variances from the server.
    // returns an object mapping study -> mean or stdev -> values
    var key_name = platform.Platform + '_' + gene.gene_id
    var study2data = {};
    var from_index_db = await get(key_name);
    if(from_index_db !== undefined){
        var ret = JSON.parse(from_index_db);
        return ret;
    }

    var url = 'https://touchomics.s3.amazonaws.com/annotations/genes_mv/' + platform.Platform + '/' +  gene.gene_id + '_mv.json.gz';
    return fetch(url).then(response => checkStatus(response) && response.arrayBuffer())
        .then(buffer => {
            // eslint-disable-next-line no-undef
            var inflated = pako.inflate(buffer, {
                to: 'string'
            });
            var response = JSON.parse(inflated);
            for(var i=0; i < response.studies.length; i++){
                study2data[response.studies[i]] = {'mean': 0, 'stdev': 0};
                study2data[response.studies[i]].mean = response.means[i]
                study2data[response.studies[i]].stdev = response.stds[i]
            }
            set(key_name, JSON.stringify(study2data));
            return study2data
        })
}
