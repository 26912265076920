import {layout} from "../dom";
import {save_analytics_event} from "../analytics/analytics";

$("#help-icon-study").show();
$("#help-icon-studies").hide();
$("#help-icon-platform").hide();

export function setup_helpers(){
    document.getElementById("help-icon-platform").onclick = function() {
        const platforms = document.getElementById("platforms-help");
        if(platforms.style.display === '' | platforms.style.display === 'block'){
            platforms.style.display = 'none';
            platforms_studies.style.display = 'none';
            platforms_enrichment.style.display = 'none';
        }else if(platforms.style.display === 'none'){
            save_analytics_event({
                name: 'help_content',
                attributes: {
                    'type': 'help_click'
                }
            });
            platforms.style.display = 'block';
            platforms_studies.style.display = 'block';
            platforms_enrichment.style.display = 'block';
        }
    }

    document.getElementById("help-icon-studies").onclick = function() {
        const one_platform = document.getElementById("one-platform-help");
        const one_platform_studies = document.getElementById("one-platform-help-studies");
        const one_platform_enrichment = document.getElementById("one-platform-help-enrichment");
        if(one_platform.style.display === '' | one_platform.style.display === 'block'){
            one_platform.style.display = 'none';
            one_platform_studies.style.display = 'none';
            one_platform_enrichment.style.display = 'none';
        }else if(one_platform.style.display === 'none'){
            save_analytics_event({name: 'help_content', attributes: {'type': 'help_click'}});
            one_platform.style.display = 'block';
            one_platform_studies.style.display = 'block';
            one_platform_enrichment.style.display = 'block';
        }
    }

    document.getElementById("help-icon-study").onclick = function() {
        const one_study = document.getElementById("one-study-help");
        const one_study_pubmed = document.getElementById("one-study-help-pubmed");
        const search_help = document.getElementById("search-help");
        const edit_help = document.getElementById("edit-help");
        const one_study_studyinfo = document.getElementById("one-study-help-studyinfo");
        if(one_study.style.display === '' | one_study.style.display === 'block'){
            one_study.style.display = 'none';
            one_study_pubmed.style.display = 'none';
            edit_help.style.display = 'none';
            search_help.style.display = 'none';
            one_study_studyinfo.style.display = 'none';
        }else if(one_study.style.display === 'none'){
            save_analytics_event({name: 'help_content', attributes: {'type': 'help_click'}});
            one_study.style.display = 'block';
            one_study_pubmed.style.display = 'block';
            edit_help.style.display = 'block';
            search_help.style.display = 'block';
            one_study_studyinfo.style.display = 'block';
        }
    }
}



