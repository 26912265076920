console.log("upload doc loaded...");

import { Auth } from 'aws-amplify';
import { Storage } from "aws-amplify"
Storage.configure({ level: 'public' });

//
export const addDocument = async () => {
    // var x = await Storage.put("test4.txt", "Hello");
    // console.log('adding document');
    // console.log(x);
}

export function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    const clickHandler = () => {
        setTimeout(() => {
            URL.revokeObjectURL(url);
            a.removeEventListener('click', clickHandler);
        }, 150);
    };
    a.addEventListener('click', clickHandler, false);
    a.click();
    return a;
}
