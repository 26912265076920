export function substringMatcher(strs) {
  return function findMatches(q, cb) {
    if(q.length <= 1){
      return
    }
    var matches;

    if (q === '') {
      matches = [{"value": "Eln"}, {"value": "Myh11"}, {"value": "Mmp7"}];
    }else{
      // an array that will be populated with substring matches
      matches = [];

      // regex used to determine if a string contains the substring `q`
      var substrRegex = new RegExp('^'+q, 'i');
      var substrRegexAnyWhere = new RegExp(q, 'i');

      // iterate through the pool of strings and for any string that
      // contains the substring `q`, add it to the `matches` array
      var symbol = [];
      var synonyms = [];
      var full_text = [];
      var exact = []
      $.each(strs, function(i, str) {
        var l1 = substrRegex.test(str.Symbol);
        var l2 = substrRegexAnyWhere.test(str.Synonyms);
        var l3 = substrRegexAnyWhere.test(str.Full_name_from_nomenclature_authority);
        var l4 = substrRegexAnyWhere.test(str.Other_designations);
        var hit = {'value': str.Symbol,'gene_id': str.GeneID, 'name': str.Full_name_from_nomenclature_authority, 'wait': 1}
        if(l1){
          if(str.Symbol.toLowerCase() === q.toLowerCase()){
            exact=[hit];
          }else{
            symbol.push(hit);
          }
        }else if(l2){
          synonyms.push(hit);
        }else if(l3 || l4){
          full_text.push(hit);
        }
      });
      matches = exact.concat(symbol.concat(synonyms, full_text))
    }
    cb(matches);
  };
};
