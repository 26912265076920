import {get_traces_scatter, scatter_lasso} from "../../../util/plot-components/scatterplot";
import {layout, toggle_orderby} from "../../../dom";
import {setup_one_platform_view} from "../one-platform-view";
import {add_lasso, set_selection_active} from "./lasso";
import {reset_xy} from "../../../util/plot-components/plotly-chart";
import {save_analytics_event} from "../../../analytics/analytics";

// get the main plotting html element
var graphDiv = document.getElementById('one-platform-chart');
var data;
var x_axis = false;
var y_axis = false;
var lasso_ready = false;
var lasso;
var columns;
var update = false;

export function purge_platform_plot(){
    Plotly.purge(graphDiv);
}


export function samples_worker_listener(msg){
    if(localStorage.view === 'one_platform') {
        if (msg.data.msg) {
            switch(msg.data.msg.method ){
                case 'GET_ALL':
                    columns = msg.data.platform.blob.columns;
                    lasso_ready = true;
                    show_one_platform_plot();
                    break;
            }
        }
    }
}

export function gene_worker_listener(msg){
    try{
        var tag = msg.data.msg.tag;
        update = false;
        if(tag == 'x_axis'){
            x_axis = msg.data;
            update = true;
        }else if(tag == 'y_axis'){
            update =  true;
            y_axis = msg.data;
        }
        show_one_platform_plot(true);
        $("#spinner_loading_input").hide();
    }catch(e){}
}

var show_one_platform_plot = async function(){
    if(x_axis &&  y_axis && update && lasso_ready){
        await plot();
    }
}

hotkeys('ctrl+s', function(event,handler) {
    switch(handler.key){
        case "ctrl+s":
            console.log('updating');
            break;

    }
});

export function reset_forms(){
    $('#gene_1').val(x_axis.gene.Symbol);
    $('#gene_2').val(y_axis.gene.Symbol);
    reset_xy(x_axis,y_axis)
}

export function redraw(){
    plot()
}

var modeBarButtonsToAdd = [{
    name: 'Download',
    icon: Plotly.Icons.camera,
    click: function(gd) {
        const view = localStorage.view;
        var platform = JSON.parse(localStorage.getItem('platform'));
        var filename = platform.Platform + '_' + x_axis.gene.Symbol + '_vs_'+y_axis.gene.Symbol;
        save_analytics_event({name: 'download_image', attributes: {'type': 'download',
                filename:filename,
                view: view,
                x_gene: x_axis.gene.Symbol,
                y_gene: y_axis.gene.Symbol
            }});
        Plotly.downloadImage(gd, {format: 'svg', filename:filename})
    }
}];


async function plot() {
    var platform = JSON.parse(localStorage.getItem('platform'));
    var show_all = true;
    data = await get_traces_scatter(x_axis, y_axis, 'scattergl', false, show_all, true);
    data[0].name = platform.Platform;
    lasso = scatter_lasso(x_axis, y_axis);
    lasso.showlegend = false;
    lasso.legend = undefined;
    toggle_orderby(false);
    console.log(lasso);
    Plotly.newPlot(graphDiv, data, lasso,
        {"responsive": true, modeBarButtonsToAdd:modeBarButtonsToAdd});
    add_lasso(lasso, columns);
    setup_one_platform_view();
}

