import {get_traces_scatter, scatter_lasso} from "./scatterplot.js";
import {bar_lasso, get_traces_bar} from "./barchart.js";
import {boxplot_lasso, get_traces_boxplot} from "./boxplot.js";
import {toggle_orderby, toggle_x_axis, layout} from "../../dom.js";
import {get, set} from "../index_db.js";
import {reset_forms} from "../../views/one-platform/scatter-chart/coordinator";
import {save_analytics_event} from "../../analytics/analytics";

var graphDiv = document.getElementById('study-view-chart');
var x_axis = false;
var y_axis = false;
var lasso_ready = false;
var plotly_type = 'scattergl';
var current_grouping = false;
var current_ordering = false;
var update = false;
var outer_x_axis = false;
var outer_y_axis = false;


var modeBarButtonsToAdd = [{
    name: 'toImage',
    icon: Plotly.Icons.camera,
    click: function(gd) {
        const view = localStorage.view;
        var platform = JSON.parse(localStorage.getItem('platform'));
        var study_accession = localStorage.getItem("study_accession");
        var filename = '';
        switch(plotly_type){
            case 'scattergl':
                filename = platform.Platform + '_' + study_accession + '_' + x_axis.gene.Symbol + '_vs_'+y_axis.gene.Symbol + '_scatter'
                break;
            case 'box':
                filename = platform.Platform + '_' + study_accession + '_' + y_axis.gene.Symbol + '_boxplot'
                break;
            case 'bar':
                filename = platform.Platform + '_' + study_accession + '_' + y_axis.gene.Symbol + '_barchart'
                break;

        }
        save_analytics_event({name: 'download_image', attributes: {'type': 'download',
                filename:filename,
                view:view,
                x_gene: x_axis.gene.Symbol,
                y_gene: y_axis.gene.Symbol,
                study: study_accession
            }});
        Plotly.downloadImage(gd, {format: 'svg', filename:filename})
    }
}];


export function gene_worker_listener_onestudy(msg, plot){
    try{
        var tag = msg.data.msg.tag;
        update = false;
        if(tag == 'x_axis'){
            x_axis = msg.data;
            update = true;
        }else if(tag == 'y_axis'){
            update =  true;
            y_axis = msg.data;
        }
        if(plot === true){
            show_plot(true);
            $("#spinner_loading_input").hide();
        }
    }catch(e){}
}

export function reset_xy(x,y){
    x_axis = x;
    y_axis = y
}

var show_plot = async function(no_grouping){
    if(plotly_type === 'scattergl'){
        if(no_grouping === true){
            await plot(x_axis, y_axis, plotly_type, false);
        }else{
            await plot(x_axis, y_axis, plotly_type, current_grouping, current_ordering);
        }

    }else{
        // reorder expression data for the study
        var study = await update_study();
        await plot(
            {data:study.x, gene:x_axis.gene},
            {data:study.y, gene:y_axis.gene},
            plotly_type,
            current_grouping,
            current_ordering);
    }
}

hotkeys('ctrl+a,ctrl+p,ctrl+i,ctrl+o,ctrl+h,command+left', function(event,handler) {
    switch(handler.key){
        case "ctrl+a":
            console.log('hotkey');
            console.log(graphDiv);
            console.log(graphDiv.layout);
            console.log(graphDiv.data);
            console.log(graphDiv.config);
            graphDiv.data.map( (trace, index) => {console.log(trace)});
            break;
        case "ctrl+p":
            layout({'data': {'view': 'all_platforms'}})
            break;
        case "command+left":
            console.log('back arrow');
            event.preventDefault();
            break;
        case "ctrl+o":
            console.log(graphDiv.data);
            console.log(graphDiv.layout);
            console.log(graphDiv.config);
            get('study').then( (x) => {
                var study = JSON.parse(x);
                console.log(study);
            });
            console.log(x_axis);
            console.log(y_axis);
            // console.log(old_plot);
            // Plotly.newPlot(graphDiv, old_plot.data, old_plot.layout,{responsive:true})
            break;

    }
});

export async function remove_study_traces(){
    var platform = JSON.parse(localStorage.getItem('platform'));
    var indices = [];
    graphDiv.data.map( (trace, index) => {
        if(trace.name !== platform.Platform){
            console.log('removing')
            console.log(trace.name)
            indices.push(index)
        }
    });
    Plotly.deleteTraces('study-view-chart', indices);
    graphDiv.data.map( (trace, index) => {trace.visible = true});
    graphDiv.layout.dragmode = 'zoom';
    Plotly.redraw(graphDiv)
}

export async function remove_study(){
    graphDiv.removeAllListeners("plotly_click");
    plotly_type = 'scattergl';
    reset_forms()
    localStorage.setItem('plotly_type', plotly_type);
}

export async function update_plot(type, value, redraw_plot=true){
    var study = JSON.parse(await get('study'));
    switch(type){
        case 'grouping':
            current_grouping = value;
            localStorage.setItem('current_grouping', value);
            if(plotly_type === 'scattergl'){
                var traces = await get_traces_scatter(
                    {data:study.x, gene:x_axis.gene},
                    {data:study.y, gene:y_axis.gene},
                    'scattergl',
                    current_grouping,
                    false);
                await remove_study_traces();
                if(traces.length === 1){
                    traces[0].marker.color = 'red';
                    traces[0].marker.opacity = 1.0;
                    traces[0].marker.size = 5.0;
                    if(current_grouping === false){
                        traces[0].name = study.Accession;
                    }
                }
                Plotly.addTraces(graphDiv, traces);
                return;
            }
            break;
        case 'orderby':
            localStorage.setItem('current_ordering', value);
            current_ordering = value;
            break;
        case 'configure':
            console.log("value");
            console.log(value);
            localStorage.setItem('plotly_type', value);
            if(value === 'scattergl' && value !== plotly_type){
                toggle_x_axis(true);
                plotly_type = value;
                await plot(x_axis, y_axis, plotly_type, false);
                return;
            }else{
                await remove_study_traces();
            }
            toggle_x_axis(false);
            plotly_type = value;
            break;
    }
    if(redraw_plot === true){
        plot({data:study.x, gene:x_axis.gene}, {data:study.y, gene:y_axis.gene},
            plotly_type, current_grouping, current_ordering);
    }
}

export async function refresh_plot(){
    var study = JSON.parse(await get('study'));
    plot({data:study.x, gene:x_axis.gene}, {data:study.y, gene:y_axis.gene},
        plotly_type, current_grouping, current_ordering);
}

var update_study = async function(){
    var study = JSON.parse(await get('study'));
    study.samples.map( (sample) => {
        if(sample.visible===undefined){
            sample.visible=true;
        }
    })
    var columns = _.pluck(study.samples, 'column');
    study.x = columns.map(column => x_axis.data[column]);
    study.y = columns.map(column => y_axis.data[column]);
    set('study', JSON.stringify(study));
    return study;
}

export function reset_plot(){
    current_grouping = false;
    current_ordering = false;
    localStorage.setItem('current_grouping', false);
    localStorage.setItem('current_ordering', false);
}

export function update_chart_data(selected_rows){
    console.log(x_axis);
    console.log(y_axis);
    console.log(selected_rows);
    console.log(graphDiv.data);
    console.log(graphDiv.layout);
}

export async function plot_study() {
    var platform = JSON.parse(localStorage.getItem('platform'));
    if(localStorage.view === 'study_view' && plotly_type === 'scattergl'){
        var study = await update_study();
        var traces = await get_traces_scatter({data:study.x},
            {data:study.y},
            'scattergl',
            current_grouping, false);
        if(traces.length == 1){
            traces[0].marker.color = 'red';
            traces[0].marker.opacity = 1.0;
            traces[0].marker.size = 5.0;
            traces[0].name = study.Accession;
        }
        console.log(traces);
        var data = await get_traces_scatter(x_axis, y_axis, 'scattergl', false, false, true);
        data[0].name = platform.Platform;
        var lasso = scatter_lasso(x_axis, y_axis);
        lasso.dragmode = false;
        lasso.selections = [];
        toggle_orderby(false);
        Plotly.newPlot(graphDiv, data, lasso, {"responsive": true, doubleClick: true,editable: false,modeBarButtonsToAdd:modeBarButtonsToAdd});
        Plotly.addTraces(graphDiv, traces);
    }
}

export function purge_plot(){
    update = false;
    x_axis = false;
    y_axis = false;
    lasso_ready = false;
    Plotly.purge(graphDiv);

}

async function plot(x, y, plotly_type, grouping=false, ordering = false) {
    var platform = JSON.parse(localStorage.getItem('platform'));
    var data;
    var lasso;
    var show_all = true;
    if (plotly_type === 'scattergl') {
        data = await get_traces_scatter(x, y, 'scattergl', grouping, show_all, true);
        if(grouping === false){
            data[0].name = platform.Platform;
        }
        lasso = scatter_lasso(x, y);
        toggle_orderby(false);
    } else if (plotly_type === 'bar') {
        console.log('grouping')
        console.log(grouping)
        data = await get_traces_bar(x, y, 'bar', grouping, ordering);
        lasso = bar_lasso(x, y);
        toggle_orderby(true);
    } else if (plotly_type === 'box') {
        console.log('grouping')
        console.log(grouping)
        console.log(ordering);
        data = await get_traces_boxplot(x, y, 'box', grouping, ordering);
        lasso = boxplot_lasso(x, y);
        toggle_orderby(true);
    }
    Plotly.newPlot(graphDiv, data, lasso, {"responsive": true, doubleClick: true, editable: false, modeBarButtonsToAdd:modeBarButtonsToAdd});
    graphDiv.on('plotly_click', function(e){
        console.log('double click');
        console.log(e);
    })
    await plot_study();
}

