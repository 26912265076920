import { add_grouping, get_order } from './plot-utils.js';
import {get, set} from '../index_db.js';
import {hide_non_visible_samples} from "../study";
var current_grouping = false;
var current_ordering = false;

export var blank_trace_boxplot = function(type){
    return {
        type: "box",
        marker: {
            line: {
                width: 1,
                color: '#404040'}
        },
        y: []
    }
}

export var get_traces_boxplot = async function(x, y, plotly_type, grouping, ordering){
    current_ordering = ordering;
    var study = await hide_non_visible_samples(x, y);
    x.data=study.x;
    y.data=study.y;
    console.log('study in boxplot')
    console.log(study)
    console.log(x);
    console.log(y);
    console.log(current_ordering);
    if(grouping === false) {
        return one_trace_boxplot(x,y,plotly_type, study);
    }
    var groups = {};
    var grouping_key = grouping;
    if(!_.isString(grouping)){
        grouping_key = grouping.join(",")
        add_grouping(grouping, study);
    }
    // set the data values to be plotted
    for(var i=0; i < x.data.length; i++) {
        study.samples[i]['x'] = i;
        study.samples[i]['y'] = y.data[i];
    }
    // reorder samples by ordering scheme, then update the indexes on each sample
    study.samples2 = JSON.parse(JSON.stringify(study.samples))
    get_order(study.samples2, current_ordering);
    study.samples2.map(sample => {
        if(grouping_key in sample){
            if(!(sample[grouping_key] in groups)){
                groups[sample[grouping_key]] = blank_trace_boxplot(plotly_type);
                groups[sample[grouping_key]]['name'] = sample[grouping_key];
            }
            groups[sample[grouping_key]]['y'].push(sample.y);
        }
    })
    return Object.values(groups);
}

export var one_trace_boxplot = function(x,y,plotly_type,study){
    var groups = {};
    groups['all'] = blank_trace_boxplot(plotly_type);
    // set the data valus to be plotted
    for(var i=0; i < x.data.length; i++) {
        study.samples[i]['x'] = i;
        study.samples[i]['y'] = y.data[i];
    }
    // reorder samples by ordering scheme, then update the indexes on each sample
    study.samples2 = JSON.parse(JSON.stringify(study.samples))
    get_order(study.samples2, current_ordering);
    //study.samples2.sort((a, b) => a.duration.localeCompare(b.duration) || a.treatment.localeCompare(b.treatment));
    for(var i=0; i < study.samples2.length; i++){
        study.samples2[i]['x'] = i;
        groups['all']['y'].push(study.samples2[i].y);
    }
    groups['all']['name'] = 'All';
    return Object.values(groups);
}



export var boxplot_lasso = function(x,y){
    const view = localStorage.view;
    var title = ''
    switch(view){
        case 'one_platform':
            var platform = JSON.parse(localStorage.getItem('platform'));
            title = platform.Platform;
            break
        case 'study_view':
            title = localStorage.getItem('study_accession');
            break;
    }
    var lasso = {
        dragmode: 'zoom',
        hovermode:'closest',
        autosize: true,
        showlegend: true,
        title: title,
        legend: {
            x: 1,
            y: 0.5
        },
        margin: {
            "b":50,
            "l":50,
            "r":25,
            "t":0,
        },
        xaxis: {
            title: {
                text: '',
                font: {
                    family: 'Courier New, monospace',
                    size: 20,
                    color: '#000000'
                }
            },
            automargin: true,
            zeroline: false,
        },
        yaxis: {
            range: [4,15],
            title: {
                text: y.gene.Symbol,
                font: {
                    family: 'Courier New, monospace',
                    size: 26,
                    color: '#000000'
                }
            },
            zeroline: false,
        }
    };
    return lasso;
}
