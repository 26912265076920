// var platform_aggs;
export function samples_worker_listener_for_aggs(msg){
    // if the user is no on the one_platform view, then return.
    if(localStorage.view !== 'one_platform') {
        return
    }
    // for each metadata key, form the table data.
    // then call the make_table command to draw the table
    if (msg.data.msg) {
        switch(msg.data.msg ){
            case 'agg':
                var all_metadata = [];
                Object.keys(msg.data.value.metadata).map( key => {
                    if(key !== 'all'){
                        Object.keys(msg.data.value.metadata[key]).map( value => {
                            var dt = msg.data.value.metadata[key][value];
                            if(msg.data.indices !== false && msg.data.indices !== undefined){
                                dt['ratio'] = dt['count'] / dt['totals']
                                dt['ratio'] = dt['ratio'].toFixed(2)
                                dt['expected_ratio'] = msg.data.value.metadata['all'][key]['count'] / dt['totals']
                                dt['total_count'] = msg.data.value.metadata['all'][key]['count']
                                dt['expected_ratio'] = dt['expected_ratio'].toFixed(2)
                                if(dt['totals'] > 10){
                                    all_metadata.push(dt);
                                }
                            }else{
                                dt['ratio'] = 1
                                dt['totals'] = dt['count']
                                all_metadata.push(dt);
                            }

                        });
                    }
                })
                make_table(all_metadata);
                break;
        }

    }

}


var table2 = false;
var select = false;

var make_table = function(metadata){
    if(table2 !== false){
        table2.clear();
        table2.destroy();
        $('#sampleAnnotation').html('<table className="table table-responsive"></table>');
    }
    var columns = [
        {data: "key", name: "key", title: "Key", searchable: true},
        {data: "value", name: "value", title: "Value", searchable: true},
        {data: "count", name: "Count", title: "Total", searchable: false}
    ];
    var col_order_index = 2;
    if('totals' in metadata[0]){
        col_order_index = 4;
        columns[2].title='Selected';
        columns.push({data: "totals", name: "totals", title: "Total", searchable: false})
        columns.push({data: "ratio", name: "ratio", title: "Enrichment", searchable: false})
        // columns.push({data: "expected_ratio", name: "Expected Ratio", title: "Expected Ratio", "searchable": false})
        // columns.push({data: "total_count", name: "total_count", title: "total_count", "searchable": false})

    }

    // // Custom range filtering function
    // $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
    //     console.log(settings);
    //     console.log(data);
    //     console.log(dataIndex);
    // });

    table2 = $('#sampleAnnotation').DataTable({
        data: metadata,
        pageLength:100,
        retrieve:true,
        select:false,
        fixedHeader: false,
        buttons: ['copy'],
        order: [[col_order_index, 'desc']],
        columns: columns
    });


    if(select === false){
        select = true;
        table2.on('select', function (e, dt, type, indexes) {
            console.log("select is happening in the sample annotation table");
            console.log(indexes);
            console.log(table2);
            console.log(dt);
        });
    }
}