import {setupColorByCheckboxes} from "../../util/plot-components/colorby-checkboxes.js";
import {setuporderbyCheckboxes} from "../../util/plot-components/orderby-checkboxes.js";
import {get, set} from '../../util/index_db.js';
import {save_study} from "../one-platform/studies-table/studies-table";
import {initialize_annotation_table} from "./editor.js"

var initial_keys;

function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}


var addEventListeners = function(){
    document.getElementById("add").onclick = async function() {
        //  listener for the add button which is clicked when a user wants to add
        //  a new column and values.
        var study = JSON.parse(await get('study'));
        var column_name = document.getElementById("column_name").value;
        if(column_name !== 'tissueType' && ['Accession', 'scanDates', 'scanTimes', 'md5', 'lastUpdate', 'species', 'title', 'summary', 'studies','Platform', 'column'].includes(column_name)){
            document.getElementById("warning").textContent='cannot modify protected key';
            return;
        }
        // initialize the entry for every sample to a blank string.
        if(!(column_name in study.samples[0])){
            study.samples.map( (sample) => {
                sample[column_name] = '';
            })
        }
        // turn off any warnings currently shown in UI.
        document.getElementById("warning").textContent='';
        // get the column value and then update any rows currently shown in the #studyAnnotation datatable.
        var column_value = document.getElementById("column_value").value;
        if(isNumeric(column_value)){
            column_value = +column_value;
        }
        var table = $('#studyAnnotation').DataTable();
        var rows = table.rows( {search:'applied'} ).data();
        var to_update = _.groupBy(rows, 'Accession');
        study.samples.map( (sample) => {
            if(sample.Accession in to_update){
                sample[column_name] = column_value;
            }
        })
        // update the sample entries in the database, then either recreate the metadata table or
        // write the error to the console.
        db_samples.annotations.bulkPut(study.samples).then(function(lastKey) {
            set('study', JSON.stringify(study));
            initialize_annotation_table();
            setupRemoveCheckboxes();
            setupColorByCheckboxes();
            setuporderbyCheckboxes();
            save_study()
            // bc_load_study.postMessage(study);
        }).catch(Dexie.BulkError, function (e) {
            console.log(e);
            console.error(e.failures.length + " did not get added");
        });
    };


    document.getElementById("remove").onclick = async function() {
        //  listener for the remove button which is clicked when a user wants to remove an
        //  unprotected column from one or more samples
        //  it uses the initial_keys and samples fields of the study object.
        //  any key selected to be removed is deleted from the samples object, then the new records
        //  are saved to the samples database.
        var study = JSON.parse(await get('study'));
        initial_keys = {};
        study.samples.map(sample => { _.mapObject(sample,  function(val, key) {initial_keys[key]=1})})
        initial_keys = Object.keys(initial_keys);
        for (var value of initial_keys) {
            if (['Accession', 'ae_accession', 'scanDates', 'scanTimes', 'md5', 'lastUpdate', 'species', 'title','summary', 'studies','Platform', 'column'].includes(value)){
                continue;
            }
            if(document.getElementById('option-' + value).checked === true){
                study.samples.map( (sample) => {
                    delete sample[value]
                })
                initial_keys = initial_keys.filter(a => a !== value);
            }
            // remove the checkbox and labels
            document.getElementById('option-' + value).remove();
            document.getElementById(value+'-label').remove();
            document.getElementById(value+'+1').remove();
        }
        // update the sample records in the db, then redraw the table or write the error to the console.
        db_samples.annotations.bulkPut(study.samples).then(function(lastKey) {
            set('study', JSON.stringify(study));
            initialize_annotation_table();
            setupRemoveCheckboxes();
            setupColorByCheckboxes()
            setuporderbyCheckboxes();
            save_study()
            // bc_load_study.postMessage(study);
        }).catch(Dexie.BulkError, function (e) {
            console.log(e);
            console.error(e.failures.length + " did not get added");
        });
    }

}

export async function setupRemoveCheckboxes(){
    addEventListeners();
    // sets up the remove checkboxes which users interact with to remove unprotected columns from the table
    // it creates a checkbox for each item in the initial_keys field of the study.
    var study = JSON.parse(await get('study'));
    initial_keys = {};
    study.samples.map(sample => { _.mapObject(sample,  function(val, key) {initial_keys[key]=1})})
    initial_keys = Object.keys(initial_keys);
    var container = document.getElementById('checkboxes');
    if(container){
        container.innerHTML='';
    }
    for (var value of initial_keys) {
        if (['Accession', 'ae_accession', 'scanDates', 'scanTimes', 'md5', 'lastUpdate', 'species', 'title','summary', 'studies','Platform', 'column'].includes(value)){
            continue;
        }
        var myEle = document.getElementById('option-' + value);
        if(!myEle){
            $('#checkboxes').append(`<div class="form-check" id="${value}+1">`)
            .append(`<input class="form-check-input" type="checkbox" id="option-${value}" name="interest" value="${value}">`)
            .append(`<label id="${value}-label" class="form-check-label" for="${value}">${value}</label></div>`)
            .append(`</div>`)
        }
    }
}
