import {get, set} from '../../util/index_db.js';
// controls the details tab
var study;
export async function updateStudyDetails(){
    var study = JSON.parse(await get('study'));
    console.log('study in study details');
    console.log(study);
    document.getElementById("title").textContent=study.attributes.title;
    // document.getElementById("pubmedids").textContent=study.attributes.pubmedids;
    document.getElementById("description").textContent=study.description;
    var a = document.getElementById('geo_link'); //or grab it by tagname etc
    a.href = "https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=" + study.Accession
}
