import {samplesWorker} from "../../../workers";
import {create_study_table, setSelectedStudies} from "../studies-table/studies-table";
import {samples_worker_listener_for_aggs} from "../sample-aggs";
import {redraw} from "./coordinator";
var selection_active = false;

samplesWorker.addEventListener("message", function handleMessageFromWorker(msg) {
    if (localStorage.view === 'one_platform') {
        if (msg.data.msg) {
            switch (msg.data.msg) {
                case 'agg':
                    samples_worker_listener_for_aggs(msg);
                    break;
            }
        }
    }
});

export function set_selection_active(value){
    selection_active = value;
}


export function add_lasso(lasso, columns){
    var graphDiv = document.getElementById('one-platform-chart');
    console.log("columns");
    console.log(columns)
    // if on one_platform then update the sample aggregations
    // as the lasso has been removed
    samplesWorker.postMessage({method: "AGG_LIST", indices: false});
    graphDiv.on('plotly_selected', function(eventData) {
        console.log('hello');
        $("#one-platform-chart.select-outline").remove();
        if(eventData !== undefined && eventData.points.length > 0){
            var indices = _.pluck(eventData.points, 'pointIndex');
            samplesWorker.postMessage({method:  "AGG_LIST", 'indices': indices});
            selection_active = true;
            var selected = {};
            var indices = _.pluck(eventData.points, 'pointIndex');
            _.map(indices, function(num){
                if(columns[num] !== undefined && !_.isNull(columns[num])){
                    columns[num].map(study => {
                        if(!(study in selected)){
                            selected[study] = 0;
                        }
                        selected[study] += 1;
                    })
                }else{
                    // console.log(num);
                }
            });
            console.log(selected);
            setSelectedStudies(selected)
            create_study_table();
        }else{
            if(selection_active){
                setSelectedStudies()
                create_study_table();
                samplesWorker.postMessage({method:  "AGG_LIST", indices:false});
                selection_active = false;
            }
            lasso.selections = [];
            redraw()
            // Plotly.relayout(graphDiv, lasso)
        }
    })
}

