import {Storage} from "aws-amplify";
import {checkStatus} from "../../util/check-status";
import {save_study} from "../one-platform/studies-table/studies-table";

async function run(){
    var level = 'public'
    var p = pako.gzip(JSON.stringify({a:1}))
    var tmpe = pako.ungzip(p, {to: 'string'});
    console.log('tmpe');
    console.log(tmpe);

    console.log(p);
    await Storage.put('tmp.gz', p, {
        level: 'public'
    });
    const result = await Storage.get('tmp.gz', { level: 'public', download: true });
    console.log('get file worked ' + level);
    console.log(result);
    var blob = await result.Body.arrayBuffer();
    var tmp = pako.ungzip(blob, {
        to: 'string'
    });
    console.log('sthi thisatlajst');
    console.log(tmp);
}

// run()
export const updateStudy = async (gse_id, content, level) => {
    // var x = await Storage.put("test4.txt", "Hello");
    // console.log('adding document');
    // console.log(x);
    // Content-Type: application/javascript
    // Content-Encoding: gzip

    if(level === undefined){
        level = 'public'
    }
    var filename = gse_id + '.json.gz';
    await Storage.put(filename, pako.gzip(JSON.stringify(content)), {
        level: level
    });
}

export async function get_study_json_old(gse_id) {
    // gets studies.json.gz file from server, then loads the blob into the study_blob table of the db.
    var URLROOT = "https://touchomics.s3.amazonaws.com/annotations/studies/";
    var studies_url = URLROOT + gse_id + '.json.gz';
    var url = studies_url;
    return fetch(url).then(response => checkStatus(response) && response.arrayBuffer())
        .then(buffer => {
            var tmp = pako.inflate(buffer, {
                to: 'string'
            });
            var study = JSON.parse(tmp);
            localStorage.setItem('save_in_db', 1);
            return study;
        }).catch(function(err){
            console.log('error retrieving study json.gz file')
            console.log(err);
            alert("Study not available. We are working on figuring out why.  Please select another study.")
            return;
        });
}


async function get_file(filename, level){
    const result = await Storage.get(filename, { level: level, download: true });
    var blob = await result.Body.arrayBuffer();
    var tmp = pako.ungzip(blob, {
        to: 'string'
    });
    var study = JSON.parse(tmp);
    var tmp = pako.ungzip(result.Body, {
        to: 'string'
    });

    return study;
}

// usage
export async function get_study_json(gse_id) {
    var filename = gse_id + '.json.gz';
    var level = 'public'
    return get_file(filename, level).then((response) => {
        return response
    }).catch((error) => {
        return get_study_json_old(gse_id)
    })
    // downloadBlob(result.Body, 'filename');
}

// download();