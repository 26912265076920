import {setup_all_platforms_view} from './all-platforms-view.js';
import {layout} from "../../dom";
import {save_analytics_event} from "../../analytics/analytics";
import {update_state} from "../../analytics/url-manager";

var all_platforms_table=false;
export function create_all_platforms_table(dataSet){
    if(all_platforms_table !== false){
        all_platforms_table.destroy()
    }
    dataSet = _.filter(dataSet, function(d){ return !d.Platform.endsWith('b') & d.total_samples > 1000});
    all_platforms_table=$('#platformsTable').DataTable({
        data: dataSet,
        dom: 'frtip',
        pageLength:100,
        order: [[4, 'desc']],
        retrieve:true,
        select: true,
        language: {
            "thousands": ","
        },
        columns: [
            { title: 'Platform', data:'Platform'},
            { title: 'Species', data:'Species'},
            { title: 'Unique Genes', data:'genes', render: function ( data, type, row, meta ) {
                    return parseInt(data).toLocaleString('en-US');
                }},
            { title: 'Total Probes', data:'probes', render: function ( data, type, row, meta ) {
                    return parseInt(data).toLocaleString('en-US');
                }},
            { title: 'Total Measurements', data:'total_measurements', visible:false},
            { title: 'Total Samples', data:'total_samples', render: function ( data, type, row, meta ) {
                    return parseInt(data).toLocaleString('en-US');
                }},
            { title: 'Total Measurements', data:'total_measurements_str', orderData:5}
        ]
    });
    $('#platformsTable').off().on('select.dt', function ( e, dt, type, indexes ) {
        var platform = dt.rows(indexes).data();
        update_state(platform[0], '?platform='.concat(platform[0].Platform));
        save_analytics_event({name: 'platform_view', attributes: {"Platform": platform[0].Platform}});
        layout({'data': {view: 'one_platform', platform: platform[0]}})
    });
}
