import { initialize_all_platforms_view } from './views/all-platforms/all-platforms-view.js';
import { initialize_one_platform_view } from './views/one-platform/one-platform-view.js';
import { set_platform_breadcrumb_one_study, set_platform_breadcrumb_one_platform } from './util/breadcrumb.js';
import { plot_study, purge_plot } from "./util/plot-components/plotly-chart.js";
import {setConfigureChartbyCheckboxes} from "./util/plot-components/configurechart-checkboxes.js";
import  {setuporderbyCheckboxes} from './util/plot-components/orderby-checkboxes.js';
import {setupColorByCheckboxes} from "./util/plot-components/colorby-checkboxes.js";
import {setupRemoveCheckboxes} from "./views/one-study-view/annotater.js";
import { initialize_annotation_table} from "./views/one-study-view/editor.js";
import {initialize_study_view} from './views/one-study-view/study-view.js';
import {setup_helpers} from "./util/help-content";
import {addDocument} from "./upload-doc";
import {setup_analytics} from "./analytics/analytics";
import {set_show_hide} from "./util/plot-components/show_hide";
import {update_state} from "./analytics/url-manager";


setup_analytics()

window.addEventListener("popstate", (event) => {
    console.log('postate event')
    console.log(event)
    console.log(event.id);
    history.go(1)
    event.preventDefault();
    console.log(localStorage.breadcrumb_click);
    $("#back-button-warning").show();
    setTimeout(hideBackButtonWarning, 3000);
});

var hideBackButtonWarning = function(){
    $("#back-button-warning").hide();
}

var view = '';

export function layout(event) {
    console.log("doing this");
    setup_helpers();
    // if the message contains a view field then update the localStorage variable
    if(event.data.view){
        localStorage.setItem('view', event.data.view);
    }
    // if the message contains a platform field then update the localStorage variable
    if(event.data.platform){
        localStorage.setItem('platform', JSON.stringify(event.data.platform));
    }
    // if the dom is being initialized and the view is equal to study_view, then reset view to one_platform
    if(event.data.init){
        var view = localStorage.getItem('view');
        if( view === 'study_view'){
            localStorage.setItem('view', 'one_platform');
        }
    }
    // call the dom_manager function to setup view based on event
    dom_manager(event)
};


export function toggle_orderby(show){
    if(show === true){
        $("#accordionOrderBy").show();
    }else{
        $("#accordionOrderBy").hide();
    }
}

export function toggle_x_axis(show){
    if(show === true){
        $("#gene_1_form_input").show();
    }else{
        $("#gene_1_form_input").hide();
    }

}

var dom_manager = function(event){
    // Loads the view based on the contents of event
    // Start the loading icon, then show the view based on the localStorage view variable.
    spinner()
    view = localStorage.getItem('view');
    if(!view){
        view = 'all_platforms';
        localStorage.setItem('view', view);
    }
    switch(view){
        case 'all_platforms':
            initialize_all_platforms_view();
            break;
        case 'one_platform':
            initialize_one_platform_view();
            set_platform_breadcrumb_one_platform();
            break;
        case 'study_view':
            initialize_study_view()
            set_platform_breadcrumb_one_study()
            break;
    }
    update_state()
}


export function spinner(){
    //  Function to show view that is only the spinner
    $("#help-content").hide();
    $("#all_platforms").hide()
    $("#all_studies").hide()
    $("#breadcrumb").hide();
    $("#breadcrumb-studyid").hide();
    $("#breadcrumb-allplatforms").hide();
    $("#breadcrumb-allstudies").hide();
    $("#platform_or_study").hide();
    $("#one_study").hide();
    $("#accordionConfigureChart").hide();
    $("#accordionColorby").hide();
    $("#accordionOrderBy").hide();
    $("#study-info").hide();
    $("#loading-icon").show()
    $("#one-study-help").hide();
    $("#one-platform-help").hide();
    $("#platforms-help").hide();
    $("#back-button-warning").hide();
}

// called in platform-view.js
export function all_platforms_view(){
    //  Function to show all plaforms view
    localStorage.removeItem('platform');
    localStorage.removeItem('gene_1');
    localStorage.removeItem('gene_2');
    localStorage.removeItem('platform');
    // show all studies
    $("#loading-icon").show()
    $("#all_studies").hide()
    $("#breadcrumb-studyid").hide();
    $("#breadcrumb-allplatforms").show();
    $("#breadcrumb-allstudies").hide();
    $("#platform_or_study").hide();
    $("#accordionConfigureChart").hide();
    $("#accordionColorby").hide();
    $("#accordionSaveSignature").hide();
    $("#accordionOrderBy").hide();
    $("#study-info").hide();
    $("#annotater").hide();
    $("#one-study-help").hide();
    $("#one-study-help-pubmed").hide();
    $("#search-help").hide()
    $("#edit-help").hide()
    $("#one-study-help-studyinfo").hide();
    $("#one-platform-help").hide();
    $("#one-platform-help-studies").hide();
    $("#one-platform-help-enrichment").hide();
    $("#back-button-warning").hide();
    $("#help-content").show();
    $("#help-icon-study").hide();
    $("#help-icon-studies").hide();
    $("#loading-icon").hide()
    $("#all_platforms").show()
    $("#breadcrumb").show();
    $("#platforms-help").show();
    $("#help-icon-platform").show();

    //Destroys the current plot object
    purge_plot()
}

// called in one-platform-view.js
export function one_platform_view(){
    //  Function to show view for a single platform.
    document.getElementById('content-loading').innerText = 'Loading Platform Data...\nMax one minute'
    set_show_hide()
    $("#study-info").hide();
    $("#loading-icon").hide()
    $("#all_platforms").hide()
    $("#all_studies").show()
    $("#breadcrumb").show();
    $("#breadcrumb-allplatforms").show();
    $("#breadcrumb-allplatforms").addClass('active');
    $("#breadcrumb-allstudies").show();
    $("#breadcrumb-studyid").hide();
    $("#platform_or_study").show();
    $("#charts").show();
    $("#study-view-chart").hide();
    $("#accordionAxes").show();
    $("#accordionSaveSignature").show();
    $("#accordionConfigureChart").hide();
    $("#accordionColorby").hide();
    $("#accordionOrderBy").hide();
    $("#study-info").hide();
    $("#annotater").hide();
    $("#one-study-help").hide();
    $("#one-study-help-pubmed").hide();
    $("#search-help").hide();
    $("#edit-help").hide();
    $("#one-study-help-studyinfo").hide();
    $("#one-platform-help").hide();
    $("#one-platform-help-studies").hide();
    $("#one-platform-help-enrichment").hide();
    $("#platforms-help").hide();
    $("#back-button-warning").hide();
    $("#help-content").show();
    $("#help-icon-study").hide();
    $("#help-icon-studies").show();
    $("#help-icon-platform").hide();
    $("#one-platform-chart").show();
    // var graphDiv = document.getElementById('one-platform-chart');
    // Plotly.newPlot(graphDiv, graphDiv.data, graphDiv.layout);
}

export async function study_view(){
    //  Function to show view for a single study
    document.getElementById('content-loading').innerText = 'Loading Study Data...'
    $("#loading-icon").show()
    plot_study(true)
    await initialize_annotation_table();
    await setupColorByCheckboxes(true);
    await setuporderbyCheckboxes(true);
    await setupRemoveCheckboxes();
    $("#all_platforms").hide()
    $("#all_studies").hide()
    $("#annotater").show();
    $("#one-platform-chart").hide();
    $("#study-view-chart").show();
    setConfigureChartbyCheckboxes();
    $("#breadcrumb").show();
    $("#breadcrumb-allplatforms").show();
    $("#breadcrumb-allplatforms").removeClass('active');
    $("#breadcrumb-allstudies").show();
    $("#breadcrumb-allstudies").addClass('active');
    $("#study-info").show();
    $("#breadcrumb-studyid").show();
    $("#platform_or_study").show();
    $("#charts").show();
    $("#accordionAxes").show();
    $("#accordionConfigureChart").show();
    $("#gene_1_form_input").show();
    $("#accordionColorby").show();
    $("#accordionOrderBy").hide();
    $("#accordionSaveSignature").hide();
    $("#one-study-help").hide();
    $("#one-platform-help").hide();
    $("#one-study-help-pubmed").hide();
    $("#search-help").hide();
    $("#edit-help").hide();
    $("#one-study-help-studyinfo").hide();
    $("#one-platform-help-studies").hide();
    $("#one-platform-help-enrichment").hide();
    $("#back-button-warning").hide();
    $("#help-content").show();
    $("#platforms-help").hide();
    $("#help-icon-study").show();
    $("#help-icon-studies").hide();
    $("#help-icon-platform").hide();
    // history.replaceState({ page: 1 }, "One Study", "?study");
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    $("#loading-icon").hide()
    var graphDiv = document.getElementById('study-view-chart');
    Plotly.newPlot(graphDiv, graphDiv.data, graphDiv.layout);

}

$(document).ready(function(){
    console.log('window.location.href');
    var current_url = window.location.href;
    if(current_url.includes('app.html')){
        console.log('captured page ready event');
        layout({'data': {'init': true}})
    }
    Plotly.setPlotConfig(
        {
            modeBarButtonsToRemove: ['toImage', 'sendDataToCloud'],
            displaylogo: false,
            editable: false
        }
    );

});

