import {get} from "../util/index_db";
var x_axis;
var y_axis;

export async function update_state(state, url) {
    if(state === undefined){
        state = {};
    }
    Object.assign(state, {}, state);
    const view = localStorage.view;
    if(view === 'all_platforms'){
        url = '?all_platforms';
    }else{
        var platform = JSON.parse(localStorage.getItem('platform'));
        if(view === 'study_view'){
            var study = JSON.parse(await get('study'));
            url = '?platform='.concat(platform.Platform).concat("&study=").concat(study.Accession);

        }
    }
    switch (view) {
        case 'all_platforms':
            url = '?all_platforms';
            break;
        case 'one_platform':
            var platform = JSON.parse(localStorage.getItem('platform'));
            url = '?platform='.concat(platform.Platform);
            break;
        case 'study_view':
            var platform = JSON.parse(localStorage.getItem('platform'));
            var study = JSON.parse(await get('study'));
            url = '?platform='.concat(platform.Platform).concat("&study=").concat(study.Accession);
            break;

    }
    console.log('history manager');
    console.log(state);
    console.log(url);
    history.replaceState(state, "Studies", url);
}